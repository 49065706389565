import React from 'react';
import { List, Datagrid, TextField, FunctionField } from 'react-admin';
import { green, red } from '@mui/material/colors';
import { Typography } from '@mui/material';
import { invoicePaymentStatus } from '../../Services/invoicePayment';

export const InvoicePaymentList = (props: any) => {
	const getStatusName = (status: number) => {
		const statusObject = invoicePaymentStatus.find(
			(item) => item.id === status
		);
		return statusObject ? statusObject.name : 'Unknown status';
	};

	return (
		<List {...props} perPage={50}>
			<Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
				<TextField source="billingAccount.organisationName" />
				<TextField source="invoice.reference" />
				<TextField source="amount" />
				<FunctionField
					label="Status"
					render={(record) => (
						<Typography
							style={{
								display: 'inline-block',
								color: record.status === 100 ? green[500] : red[500], // Couleur conditionnelle
								fontWeight: 'bold', // Optionnel : pour mettre en avant le texte
							}}
						>
							{getStatusName(record.status)}
						</Typography>
					)}
				/>
				<TextField source="message" />
			</Datagrid>
		</List>
	);
};
