//@ts-nocheck
import React, { useState } from 'react';
import {
	BooleanInput,
	Datagrid,
	DateInput,
	EditBase,
	FunctionField,
	NumberField,
	ReferenceManyField,
	SelectInput,
	SimpleForm,
	TextField,
	TextInput,
	TopToolbar,
	useDataProvider,
	useGetOne,
	useNotify,
	useRecordContext,
} from 'react-admin';
import {
	Card,
	CardContent,
	Typography,
	Grid,
	Button,
	IconButton,
	Menu,
	MenuItem,
	Box,
} from '@mui/material';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';
import OfferDetailsModalCreate from './OfferDetails/OfferDetailsModalCreate';
import OfferDetailsModalEdit from './OfferDetails/OfferDetailsModalEdit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { billingAccountTypeChoices } from '../../Services/billingAccount';
import { useParams } from 'react-router';
import { useFieldValidation } from '../../Utils/validators';
import moment from 'moment';

const Breadcrumb = () => {
	const record = useRecordContext();
	return (
		<TopToolbar>
			<Typography variant="subtitle1">
				Home / Offers / {record ? record.name : 'Edit'}
			</Typography>
		</TopToolbar>
	);
};

export const OfferEdit = (props: any) => {
	const [createOpen, setCreateOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [defaultValues, setDefaultValues] = useState(null);
	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
	const [menuRecord, setMenuRecord] = useState(null);

	const notify = useNotify();
	const dataProvider = useDataProvider();
	const { id } = useParams();
	const { setFieldErrors } = useFieldValidation();

	const { data: record } = useGetOne('offers', { id });

	const handleCreateOpen = (defaults: any = null) => {
		setDefaultValues(defaults); // Met à jour les valeurs par défaut
		setCreateOpen(true);
	};

	const handleCreateClose = () => setCreateOpen(false);

	const handleEditOpen = (data: any) => {
		setSelectedRecord(data);
		setEditOpen(true);
	};
	const handleEditClose = () => {
		setSelectedRecord(null);
		setEditOpen(false);
	};

	const copy = (record: any) => {
		const newRecord = {
			...record,
			id: null, // Génère un nouvel ID unique
		};
		delete newRecord.createdAt; // Suppression des champs non nécessaires si applicable
		delete newRecord.updatedAt;
		handleCreateOpen(newRecord);
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, data: any) => {
		setMenuAnchorEl(event.currentTarget);
		setMenuRecord(data);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
		setMenuRecord(null);
	};

	const handleSubmit = async (data: any) => {
		try {
			await dataProvider.update('offers', {
				id: record.id,
				data,
				previousData: record,
			});
			notify('Offer successfully!', { type: 'success' });
		} catch (error: any) {
			if (error.body.fields) {
				const errors = error.body.fields.reduce(
					(
						acc: Record<string, string>,
						fieldError: { field: string; message: string }
					) => {
						acc[fieldError.field] = fieldError.message;
						return acc;
					},
					{}
				);
				setFieldErrors(errors);
			}
			notify(`Error: ${error.message}`, { type: 'error' });
		}
	};

	return (
		<EditBase {...props}>
			<React.Fragment>
				<Breadcrumb />
				{/* Utilisation d'une grille à deux colonnes */}
				<Grid container spacing={2}>
					{/* Colonne principale avec les projets associés */}
					<Grid item xs={8}>
						<Card>
							<CardContent>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginBottom: 20,
									}}
								>
									<Typography variant="h6" gutterBottom>
										Offer Details
									</Typography>
									<Button
										variant="contained"
										color="primary"
										onClick={() => handleCreateOpen(null)}
									>
										+
									</Button>
								</div>
								<ReferenceManyField
									label="Offer"
									reference="offer-details"
									target="offerId"
									sort={{ field: 'offerDetails.id', order: 'ASC' }}
								>
									<Datagrid>
										<TextField source="skuParent" label="Sku Parent" />
										<TextField source="sku" label="Sku" />
										<NumberField
											source="amount"
											label="Amount"
											options={{ style: 'currency', currency: 'EUR' }}
										/>
										<NumberField
											source="linesIncluded"
											label="Lines Included"
											options={{ useGrouping: true }}
										/>
										<TextField source="enabled" label="enabled" />
										<FunctionField
											render={(record) => (
												<>
													<IconButton
														aria-controls="simple-menu"
														aria-haspopup="true"
														onClick={(event) => handleMenuOpen(event, record)}
													>
														<MoreVertIcon />
													</IconButton>
												</>
											)}
										/>
										<FunctionField
											render={(record) => (
												<ConfirmDeleteButton
													record={record}
													title="Delete details on offer"
													entity="offer-details"
													description="Are you sure to delete this line ?"
													withRedirect={false}
													confirmMessage="Line is deleted"
												/>
											)}
										/>
									</Datagrid>
								</ReferenceManyField>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={4}>
						<Card>
							<CardContent>
								<Typography variant="h6" gutterBottom>
									Offer Details
								</Typography>
								<SimpleForm
									defaultValues={{ id: record?.id }}
									onSubmit={handleSubmit}
								>
									<TextInput source="id" disabled />
									<SelectInput
										source="offerFor"
										label="for"
										choices={billingAccountTypeChoices}
									/>
									<TextInput source="name" />
									<TextInput source="internalName" />
									<Box display="flex" flexDirection="row" width="100%">
										<Box mr={1} flex={1}>
											<DateInput
												source="startedAt"
												parse={(value) =>
													value
														? moment(value).format('YYYY-MM-DDTHH:mm:ssZ')
														: null
												}
											/>
										</Box>
										<Box ml={1} flex={1}>
											<DateInput
												source="endedAt"
												parse={(value) =>
													value
														? moment(value).format('YYYY-MM-DDTHH:mm:ssZ')
														: null
												}
											/>
										</Box>
									</Box>
									<BooleanInput label="Enabled" source="enabled" />
								</SimpleForm>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Menu
					anchorEl={menuAnchorEl}
					keepMounted
					open={Boolean(menuAnchorEl)}
					onClose={handleMenuClose}
				>
					<MenuItem onClick={() => handleEditOpen(menuRecord)}>Edit</MenuItem>
					<MenuItem onClick={() => copy(menuRecord)}>Copy</MenuItem>
				</Menu>
				<OfferDetailsModalCreate
					open={createOpen}
					handleClose={handleCreateClose}
					defaultValues={defaultValues}
					offer={record}
				/>
				<OfferDetailsModalEdit
					open={editOpen}
					handleClose={handleEditClose}
					record={selectedRecord}
					offer={record}
				/>
			</React.Fragment>
		</EditBase>
	);
};
