import { Button, Card, CardContent, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Datagrid, FunctionField, ReferenceManyField, TextField } from 'react-admin';
import BillingAccountUserModalCreate from './BillingAccountUserModalCreate';
import ConfirmDeleteButton from '../../../Components/Button/ConfirmDeleteBtn';


interface BillingAccountUsersProps {
  billingAccount: any;
}

export const BillingAccountUsers:React.FC<BillingAccountUsersProps> = ({billingAccount}) => {
  
    const [userOpen, setUserOpen] = useState(false);

    const handleUserOpen = () => setUserOpen(true);
    const handleUserClose = () => setUserOpen(false);
  

  return (
    <Card style={{ marginBottom: 20 }}>

        <BillingAccountUserModalCreate open={userOpen} handleClose={handleUserClose} billingAccountId={billingAccount?.id} />

        <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            <Typography variant="h6" gutterBottom>
            Users
            </Typography>
            <Button variant="contained" color="primary" onClick={handleUserOpen}>
                +
            </Button>
        </div>

        <ReferenceManyField
            label="Users"
            reference="billing-account-users"
            target="billingAccountId"
            sort={{ field: '"User"."name"', order: 'ASC' }}
        >
            <Datagrid bulkActionButtons={false}>
            <TextField source="user.name" label="User" />
            <TextField source="role" label="Role" />
            <FunctionField render={record => <ConfirmDeleteButton 
                record={record} 
                title='Delete billing account user' 
                entity='billing-account-users'
                description='Are you sure to delete this user ?'
                withRedirect={false}
                confirmMessage='User is deleted' />
                } />
            </Datagrid>
        </ReferenceManyField>
        </CardContent>
    </Card>
)};

