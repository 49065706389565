//@ts-nocheck
import React, { useState } from 'react';
import {
	EditBase,
	SimpleForm,
	SelectInput,
	DateInput,
	TextInput,
	MenuItemLink,
	useGetOne,
	useRecordContext,
	TopToolbar,
} from 'react-admin';
import {
	Card,
	CardContent,
	Typography,
	Grid,
	Box,
	MenuList,
	ListItemIcon,
	ListItemText,
	MenuItem,
} from '@mui/material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Routes, Route, useParams } from 'react-router-dom';
import { InvoiceStatus } from '../../Services/invoice';
import { InvoiceLibelles } from './Screens/InvoiceLibelles';
import TicketPaymentModalCreate from './TicketPaymentModalCreate';

const Breadcrumb = () => {
	const record = useRecordContext();
	return (
		<TopToolbar>
			<Typography variant="subtitle1">
				Home / Invoices / {record ? record.id : 'Edit'}
			</Typography>
		</TopToolbar>
	);
};

export const InvoiceEdit = (props: any) => {
	const { id } = useParams();
	const [ticketModalOpen, setTicketModalOpen] = useState(false);

	const handleTiketOpen = () => {
		setTicketModalOpen(true);
	};

	const handleContractClose = () => setTicketModalOpen(false);

	const { data: record } = useGetOne('invoices', { id });

	if (!record) return null;

	return (
		<EditBase {...props}>
			<React.Fragment>
				<TicketPaymentModalCreate
					open={ticketModalOpen}
					invoiceId={record.id}
					amount={record.amountTotal}
					billingAccountId={record.billingAccountId}
					handleClose={handleContractClose}
				/>
				<Breadcrumb />
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<Routes>
							<Route path="/" element={<InvoiceLibelles invoice={record} />} />
						</Routes>
					</Grid>
					<Grid item xs={4}>
						<SimpleForm sx={{ padding: 0 }} toolbar={<Box />} record={record}>
							<Card style={{ marginBottom: 20, width: '100%' }}>
								<CardContent>
									<Typography variant="h6" gutterBottom>
										Invoice Status
									</Typography>

									<TextInput source="ref" disabled />
									<SelectInput
										source="status"
										choices={InvoiceStatus}
										optionText="name"
										optionValue="id"
										disabled={true}
									/>

									<Box display="flex" flexDirection="row" width="100%">
										<Box flex={1} mr="0.5em">
											<DateInput source="periodStartedAt" disabled />
										</Box>
										<Box flex={1} mr="0.5em">
											<DateInput source="periodEndedAt" disabled />
										</Box>
									</Box>

									<Typography
										variant="h6"
										gutterBottom
										style={{ textAlign: 'center' }}
									>
										{new Intl.NumberFormat('fr-FR', {
											style: 'currency',
											currency: 'EUR',
										}).format(record?.amountTotal!)}
									</Typography>
								</CardContent>
							</Card>

							<Card style={{ marginBottom: 20, width: '100%' }}>
								<MenuList>
									<MenuItemLink to={`/billing-account-contracts/${record.id}`}>
										<ListItemIcon>
											<HistoryEduIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Contract</ListItemText>
									</MenuItemLink>
									<MenuItem onClick={handleTiketOpen}>
										<ListItemIcon>
											<CreditCardIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Add Payment request</ListItemText>
									</MenuItem>
								</MenuList>
							</Card>
						</SimpleForm>
					</Grid>
				</Grid>
			</React.Fragment>
		</EditBase>
	);
};
