import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useNotify, useRefresh, useCreate } from 'react-admin';
import Loader from '../../Components/Loader';

interface TicketPaymentModalCreateProps {
	open: boolean;
	handleClose: () => void;
	billingAccountId: string;
	invoiceId: string;
	amount: number;
}

const TicketPaymentModalCreate: React.FC<TicketPaymentModalCreateProps> = ({
	open,
	handleClose,
	billingAccountId,
	invoiceId,
	amount,
}) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [create] = useCreate();

	const [isPending, setIsPending] = React.useState(false);

	const handleSave = (data: any) => {
		setIsPending(true);
		create(
			'invoice-payments',
			{
				data: {
					billingAccountId: billingAccountId,
					invoiceId: invoiceId,
					amount: amount,
				},
			},
			{
				onSuccess: (d) => {
					setIsPending(false);
					refresh();
					notify('Ticket created successfully');
					handleClose();
				},
				onError: (error: any) => {
					setIsPending(false);
					notify(`Error: ${error.message}`, { type: 'error' });
				},
			}
		);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<Loader show={isPending} />
			<DialogTitle>Create a new payment request</DialogTitle>
			<DialogContent>
				Are you sure you want to create a new payment request?
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary" disabled={isPending}>
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					color="primary"
					variant="contained"
					disabled={isPending}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default TicketPaymentModalCreate;
