import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { QueryUsageData, get } from "../../Services/usageService";
import Loader from "../Loader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

ChartJS.register(
  CategoryScale, // Permet d'utiliser l'axe X en "category"
  LinearScale,   // Permet d'utiliser l'axe Y en valeurs numériques
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UsageDashboard:React.FC<QueryUsageData> = (queryParams) => {
  
  const [isPending, setIsPending] = useState(true);
  const [data, setData] = useState<any>(null);
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const load = async () => {
      setIsPending(true);
      try {
        const response = await get(queryParams);
        setData(response);
      } catch (error) {
        console.error("Error fetching usage data:", error);
      } finally {
        setIsPending(false);
      }
    };

    load();
  }, [queryParams]);

  useEffect(() => {
    if (data && data.results.length > 0) {
      setChartData({
        labels: data.results.map((r: any) => r.periodName),
        datasets: [
          {
            label: "Total",
            data: data.results.map((r: any) => r.nbrOfRowsTotal),
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "Billed",
            data: data.results.map((r: any) => r.nbrOfRowsBilled),
            backgroundColor: "rgba(54, 162, 235, 0.6)",
          },
          {
            label: "To Bill",
            data: data.results.map((r: any) => r.nbrOfRowsNotBilled),
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      });
    }
  }, [data]);
  console.log({data})

  return (
    
    <Box>
          <Loader show={isPending && !data} />
          {data && data.results.length > 0 &&
          <Box>
          <h2 className="text-xl font-bold mb-4">Usage evolution</h2>
          {chartData && <Bar data={chartData} />}

          <h2 className="text-xl font-bold mb-4">By SKUs</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Billed</TableCell>
                <TableCell>To bill</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.skus.map((sku: any) => (
                <TableRow key={sku.sku}>
                  <TableCell>{sku.sku}</TableCell>
                  <TableCell>{sku.nbrOfRowsBilled}</TableCell>
                  <TableCell>{sku.nbrOfRowsNotBilled}</TableCell>
                  <TableCell>{sku.nbrOfRowsTotal}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </Box>
        }

      </Box>
  );
};

export default UsageDashboard;
