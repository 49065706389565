import { Identifier } from "react-admin";
import r from "../Utils/fetch";
import { BillAccountContract } from "./billingAccountContract";

const w: any = window;
const endPoint = `${w.API_BILL}`;


export const create = (data: any): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-quotations`, method: "POST", data });

export const update= (id : Identifier, data: any): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-quotations/${id}`, method: "PATCH", data });

export const remove = (id: Identifier): Promise<void> => r({ url: `${endPoint}/v1/admin/billing-account-contract-quotations/${id}`, method: "DELETE" });