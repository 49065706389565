import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Box, Tooltip } from '@mui/material';

const steps = [
  'Add products',
  'Generate contract',
  'Prepare sign documents',
  'Send to sign',
  'Sign contract',
  'Add quotation',
];

const stepTooltips = [
    'Add the products you want to include in the contract.',
    'Clic on the button to "generate the contract" or define exeisting folders in gdrive in "documents" section.',
    'Clic on "prepare sign documents" to create the documents to sign.',
    'Clic on "send to sign" or define exeisting folders in gdrive in "documents" section and set status "to sign".',
    'Wait for the contract to be signed or define status "signed".',
    'Quotation in pennylane is necessary to generate the invoice. Create or add existing quotation in "quotation" section.',
  ];

interface StepperContractProps {
    contract: any;
  }

const StepperContract: React.FC<StepperContractProps> = ({contract}) => {
  const [activeStep, setActiveStep] = useState(0);

useEffect(() => {

    if(contract) {
      if(contract?.offerDetails && contract?.offerDetails.length > 0) {
        setActiveStep(1);
      }

      if(contract.gDriveFolderSrcId) {
          setActiveStep(2);
        }

        if(contract.yousignSignId) {
          setActiveStep(3);
        }

        if(contract.status === 'to_sign') {
          setActiveStep(4);
        }

        if(contract.status === 'signed' && contract.gDriveFolderSignedId) {
          setActiveStep(5);
        }

        if(contract.quotations && contract.quotations.length > 0) {
          setActiveStep(6);
        }
    }

    

}, [contract]);


  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
          <Step key={index}>
            <Tooltip title={stepTooltips[index]} placement="top">
              <StepLabel>{label}</StepLabel>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
      
    </Box>
  );
};

export default StepperContract;