import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import {
	useNotify,
	useRefresh,
	useRecordContext,
	useCreate,
} from 'react-admin';
import { IOffer } from '../../../Services/offer';
import OfferDetailForm from './OfferDetailForm';
import { IOfferDetail } from '../../../Services/offerDetails';

interface OfferDetailsModalCreateProps {
	open: boolean;
	handleClose: () => void;
}

const OfferDetailsModalCreate: React.FC<OfferDetailsModalCreateProps> = ({
	open,
	handleClose,
}) => {
	const record = useRecordContext<IOffer>(); // Contexte pour obtenir le user actuel
	const notify = useNotify();
	const refresh = useRefresh();
	const [create] = useCreate();

	const handleSave = (data: any) => {
		create(
			'offer-details',
			{
				data: {
					sku: data.sku,
					amount: data.amount,
					nbr: data.nbr,
					nbrType: data.nbrType,
					lines: data.lines,
					unitLine: data.unitLine,
					linesIncluded: data.linesIncluded,
					billingAccountContractId: record?.id,
				},
			},
			{
				onSuccess: () => {
					refresh();
					notify('Line created successfully');
					onClose();
				},
				onError: (error: any) => {
					notify(`Error: ${error.message}`, { type: 'error' });
				},
			}
		);
	};

	const onClose = () => {
		handleClose();
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Select SKU</DialogTitle>

			<OfferDetailForm
				onSubmit={handleSave}
				initialValues={
					{
						sku: '',
						amount: 0,
						nbr: 1,
						lines: [],
						linesIncluded: 0,
						unitLine: 10000,
					} as IOfferDetail
				}
				onCancel={() => onClose()}
			/>
		</Dialog>
	);
};

export default OfferDetailsModalCreate;
