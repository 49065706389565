import React, { useState } from 'react';
import {
	List,
	Filter,
	TextInput,
	Datagrid,
	TextField,
	FunctionField,
	TopToolbar,
	CreateButton,
} from 'react-admin';
import { Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';
import { SimulatorModal } from './SimulatorModal';

const ProjectFilter = (props: any) => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
);

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('fr-FR'); // Format français : JJ/MM/AAAA
};

const ListActions = () => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<TopToolbar>
				<CreateButton />
				<Button
					variant="text"
					color="primary"
					startIcon={<PlayArrowIcon />}
					onClick={handleOpen}
				>
					Simulator
				</Button>
			</TopToolbar>
			<SimulatorModal open={open} handleClose={handleClose} />
		</>
	);
};

export const OfferList = (props: any) => (
	<List
		{...props}
		filters={<ProjectFilter />}
		exporter={false}
		actions={<ListActions />}
	>
		<Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
			<TextField source="id" />
			<TextField source="name" />
			<TextField source="internalName" />
			<TextField source="offerFor" />
			<FunctionField
				source="startedAt"
				render={(record) => formatDate(record.startedAt)}
			/>
			<FunctionField
				source="endedAt"
				render={(record) => formatDate(record.endedAt)}
			/>
			<TextField source="enabled" />
			<FunctionField
				render={(record) => (
					<ConfirmDeleteButton
						record={record}
						title="Delete offer"
						entity="offers"
						description="Are you sure to delete this offer ?"
						confirmMessage="Offer is deleted"
					/>
				)}
			/>
		</Datagrid>
	</List>
);
