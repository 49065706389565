import React, { useState } from 'react';
import {
	ReferenceManyField,
	Datagrid,
	TextField,
	FunctionField,
} from 'react-admin';
import { Card, CardContent, Typography, Button } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { IInvoice } from '../../../Services/invoice';
import { IInvoiceLibelle } from '../../../Services/invoiceLibelle';
import { InvoiceRowsModal } from '../Modals/InvoiceRowsModal';

interface InvoiceLibellesProps {
	invoice: IInvoice;
}

export const InvoiceLibelles: React.FC<InvoiceLibellesProps> = ({
	invoice,
}) => {
	const [open, setOpen] = useState(false);
	const [selectedLibelle, setSelectedLibelle] =
		useState<IInvoiceLibelle | null>(null);

	// Ouvre la modal et stocke la ligne sélectionnée
	const handleRowClick = (record: any) => {
		setSelectedLibelle(record);
		setOpen(true);
	};

	// Ferme la modal
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Card style={{ marginBottom: 20 }}>
				<CardContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginBottom: 20,
						}}
					>
						<Typography variant="h6" gutterBottom>
							Libelles
						</Typography>
					</div>
					<ReferenceManyField
						label="Libelles"
						reference="invoice-libelles"
						target="invoiceId"
					>
						<Datagrid bulkActionButtons={false}>
							<TextField source="connectorId" label="Connector Id" />
							<TextField source="skuParent" label="Sku Parent" />
							<TextField source="sku" label="Sku" />
							<TextField source="nbrOfActiveRows" label="Active rows" />
							<TextField source="offerDetail.offer.name" label="Offer" />

							<FunctionField
								source="amountConnector"
								label="Amount Connector"
								render={(rec) =>
									new Intl.NumberFormat('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									}).format(rec.amountConnector!)
								}
							/>

							<FunctionField
								source="amountLine"
								label="Amount Lines"
								render={(rec) =>
									new Intl.NumberFormat('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									}).format(rec.amountLine!)
								}
							/>

							<FunctionField
								source="amountTotal"
								label=""
								render={(rec) => (
									<Button
										variant="text"
										color="primary"
										startIcon={<ListIcon />}
										onClick={() => handleRowClick(rec)}
									>
										rows
									</Button>
								)}
							/>
						</Datagrid>
					</ReferenceManyField>
				</CardContent>
				<InvoiceRowsModal
					open={open}
					handleClose={handleClose}
					invoiceLibelle={selectedLibelle}
				/>
			</Card>
			<Card style={{ marginBottom: 20 }}>
				<CardContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginBottom: 20,
						}}
					>
						<Typography variant="h6" gutterBottom>
							Payments
						</Typography>
					</div>
				</CardContent>
			</Card>
		</>
	);
};
