import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';


interface BillingAccountProjectsProps {
  billingAccount: any;
}

export const BillingAccountProjects:React.FC<BillingAccountProjectsProps> = ({billingAccount}) => {
  
  return (

    <Card>
    <CardContent>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Typography variant="h6" gutterBottom>
          Projects
        </Typography>
      </div>

      <ReferenceManyField
        label="Contracts"
        reference="projects"
        target="billingAccountId"
      >
        <Datagrid>
          <TextField source="name" label="Project" />
        </Datagrid>
      </ReferenceManyField>
    </CardContent>
  </Card>
)};

