import r from "../Utils/fetch";
import { BillAccountContract } from "./billingAccountContract";

const w: any = window;
const endPoint = `${w.API_BILL}`;


export interface QueryUsageData {
  filter: any
  groupBy: string
  range: [string, string]
}


const buildQueryString = (data: QueryUsageData): string => {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      params.append(key, JSON.stringify(value));
    } else {
      params.append(key, String(value));
    }
  });

  return params.toString();
};

export const get = (data: QueryUsageData): Promise<BillAccountContract> => {
  const queryString = buildQueryString(data);
  return r({ url: `${endPoint}/v1/admin/usage?${queryString}`, method: "GET" });
}
