import React from 'react';
import { ReferenceManyField, Datagrid, TextField, useRefresh, useNotify, Loading, FunctionField } from 'react-admin';
import { Card, CardContent, Typography, Button, Box, IconButton } from '@mui/material';
import BillingAccountContractDocumentQuotationModalEdit from '../Modals/BillingAccountContractDocumentQuotationModalEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as quotationService from '../../../Services/billingAccountContractQuotation';

interface BillingAccountContractQuotationProps {
  record: any;
}

export const BillingAccountContractQuotations: React.FC<BillingAccountContractQuotationProps> = ({record}) => {
  

  const [quotationOpen, setQuotationOpen] = React.useState<boolean>(false);
  const [isPending, setIsPending] = React.useState<boolean>(false);
  const refresh = useRefresh(); 
  const notify = useNotify();


  const handleDelete = async (quotation: any) => {

    if(window.confirm("Are you sure you want to delete this annexe ?")) {
      setIsPending(true);
      try {
        await quotationService.remove(quotation.id);
        refresh();
      }catch(e: any) {
        notify(e.message, { type: 'error' });
      }
      setIsPending(false);

    }

  }

  return (

        <Card style={{ marginBottom: 20 }}>

        <BillingAccountContractDocumentQuotationModalEdit open={quotationOpen}  contract={record} handleClose={() => {setQuotationOpen(false); refresh()}}  />

        {isPending && (
        <Box
          sx={{
            position: 'fixed', // Permet de couvrir toute la fenêtre
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Couleur de fond semi-transparente
            display: 'flex', // Centrer le contenu
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2500, // Assurez-vous qu'il est au-dessus des autres éléments
          }}
        >
          <Loading />
        </Box>
          )}

          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Quotations
              </Typography> 
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="small" variant="text" color="primary" onClick={() => setQuotationOpen(true)}>
                      Create Quotation
              </Button>
              </Box>
            </div>
            <ReferenceManyField
                label="Quotations"
                reference="billing-account-contract-quotations"
                target="billingAccountContractId"
              >
                <Datagrid bulkActionButtons={false}>
                  <TextField source="num" label="num" />
                  <TextField source="libelle" label="Libelle" />
                  <FunctionField
                    source="periodStartedAt"
                    label="Start Period"
                    render={(record: any) => {
                      if (!record || !record.periodStartedAt) return '';
                      const date = new Date(record.periodStartedAt);
                      return date.toLocaleDateString('fr-FR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      });
                    }}
                  />
                  <FunctionField
                    source="periodEndedAt"
                    label="End Period"
                    render={(record: any) => {
                      if (!record || !record.periodEndedAt) return '';
                      const date = new Date(record.periodEndedAt);
                      return date.toLocaleDateString('fr-FR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      });
                    }}
                  />
                 <FunctionField
                      render={(quotation) => {
                        return (
                          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <IconButton onClick={() => handleDelete(quotation)} color="primary">
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                      )}}
                    />
                </Datagrid>
              </ReferenceManyField>
           
          </CardContent>
        
        </Card>

)};

