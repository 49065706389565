import { Card, CardContent, Chip, Typography } from '@mui/material';
import React from 'react';
import {
	Datagrid,
	EditButton,
	FunctionField,
	ReferenceManyField,
	TextField,
} from 'react-admin';
import {
	INVOICE_STATUS_BILLED,
	INVOICE_STATUS_CLOSE,
	INVOICE_STATUS_PROJECT,
	InvoiceStatus,
} from '../../../Services/invoice';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

interface BillingAccountInvoicesProps {
	billingAccount: any;
}

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('fr-FR'); // Format français : JJ/MM/AAAA
};

export const BillingAccountInvoices: React.FC<BillingAccountInvoicesProps> = ({
	billingAccount,
}) => {
	const getStatusName = (status: string) => {
		const statusObject = InvoiceStatus.find((item) => item.id === status);
		return statusObject ? statusObject.name : 'Unknown status';
	};

	return (
		<Card style={{ marginBottom: 20 }}>
			<CardContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: 20,
					}}
				>
					<Typography variant="h6" gutterBottom>
						Invoices
					</Typography>
				</div>

				<ReferenceManyField
					label="Invoices"
					reference="invoices"
					target="billingAccountId"
				>
					<Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
						<TextField source="billingAccount.name" />
						<TextField source="billingAccountType" />
						<FunctionField
							source="periodStartedAt"
							render={(record) => formatDate(record.periodStartedAt)}
						/>
						<FunctionField
							source="periodEndedAt"
							render={(record) => formatDate(record.periodEndedAt)}
						/>
						<FunctionField
							label="Status"
							render={(record) => {
								switch (record.status) {
									case INVOICE_STATUS_PROJECT:
										return (
											<Chip
												icon={<PendingActionsIcon />}
												label={getStatusName(record.status)}
												color="primary"
												size="small"
											/>
										);
									case INVOICE_STATUS_CLOSE:
										return (
											<Chip
												icon={<WarningIcon />}
												label={getStatusName(record.status)}
												color="warning"
												size="small"
											/>
										);
									case INVOICE_STATUS_BILLED:
										return (
											<Chip
												icon={<CheckIcon />}
												label={getStatusName(record.status)}
												color="success"
												size="small"
											/>
										);
									default:
										return (
											<Chip
												icon={<ErrorIcon />}
												label={'unkown status'}
												color="error"
												size="small"
											/>
										);
								}
							}}
						/>
						<FunctionField
							source="amountTotal"
							label="Amount"
							render={(rec) =>
								new Intl.NumberFormat('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								}).format(rec.amountTotal!)
							}
						/>
						<FunctionField label="" render={(record) => <EditButton />} />
					</Datagrid>
				</ReferenceManyField>
			</CardContent>
		</Card>
	);
};
