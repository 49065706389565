import React from 'react';
import { ReferenceManyField, Datagrid, TextField, FunctionField, useRefresh, useNotify, Loading } from 'react-admin';
import { Card, CardContent, Typography, IconButton, Button, Box } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import BillingAccountContractDocumentAnnexeModalEdit from '../Modals/BillingAccountContractDocumentAnnexeModalEdit';
import { refreshDocument, removeDocument } from '../../../Services/billingAccountContractDocuments';
import BillingAccountContractDocumentFoldersModalEdit from '../Modals/BillingAccountContractDocumentFoldersModalEdit';

interface BillingAccountContractDocumentsProps {
  record: any;
}

export const BillingAccountContractDocuments: React.FC<BillingAccountContractDocumentsProps> = ({record}) => {
  

  const [modalAnnexeOpen, setModalAnnexeOpen] = React.useState<boolean>(false);
  const [modalFolderOpen, setModalFolderOpen] = React.useState<boolean>(false);
  const [isPending, setIsPending] = React.useState<boolean>(false);
  const refresh = useRefresh(); 
  const notify = useNotify();

  const handleOpenFolder = () => {
    window.open(`https://drive.google.com/drive/folders/${record.gDriveFolderId}`, "_blank");
  };

  const handleOpenDocument = (document: any) => {
    window.open(`https://docs.google.com/file/d/${document.fileId}`, "_blank");
  };


  const handleDeleteDocument = async (document: any) => {

    if(window.confirm("Are you sure you want to delete this annexe ?")) {
      try {
        await removeDocument(document.id);
        refresh();
      }catch(e: any) {
        notify(e.message, { type: 'error' });
      }
   }
}

const handleRefreshDocument = async (document: any) => {

  setIsPending(true);

  try {

    await refreshDocument(document.id);
    
    refresh();
    notify("document refreshed", { type: 'success' });
  }catch(e: any) {
    notify(e.message, { type: 'error' });
  }

  setIsPending(false);
}

  return (

        <Card style={{ marginBottom: 20 }}>

        {isPending && (
        <Box
          sx={{
            position: 'fixed', // Permet de couvrir toute la fenêtre
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Couleur de fond semi-transparente
            display: 'flex', // Centrer le contenu
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2500, // Assurez-vous qu'il est au-dessus des autres éléments
          }}
        >
          <Loading />
        </Box>
          )}

          <BillingAccountContractDocumentAnnexeModalEdit open={modalAnnexeOpen} contractId={record.id} handleClose={() => {setModalAnnexeOpen(false); refresh()}} />
          <BillingAccountContractDocumentFoldersModalEdit open={modalFolderOpen} contractId={record.id} handleClose={() => {setModalFolderOpen(false); refresh()}} />


          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Documents
              </Typography> 
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="small" variant="text" color="primary" onClick={() => setModalFolderOpen(true)}>
                      Set Folders
                </Button>
                <Button size="small" variant="text" color="primary" onClick={() => setModalAnnexeOpen(true)}>
                      Add annexe
                </Button>
                <Button size="small" variant="text" color="primary" onClick={handleOpenFolder}>
                      Open folder
                </Button>
              </Box>
            </div>
            <ReferenceManyField
                label="Documents"
                reference="billing-account-contract-documents"
                target="billingAccountContractId"
              >
                <Datagrid bulkActionButtons={false}>
                  <TextField source="name" label="Name" />
                  <TextField source="docType" label="Type" />
                  <TextField source="num" label="num" />
                 
                  <FunctionField
                      render={(annexe) => {
                        
                        return (
                          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {annexe.auto && (
                              <IconButton onClick={() => handleRefreshDocument(annexe)} color="primary">
                                <RefreshIcon />
                            </IconButton>
                            )}
                            {!annexe.auto && (
                              <IconButton onClick={() => handleDeleteDocument(annexe)} color="primary">
                                <DeleteIcon />
                            </IconButton>
                            )}
                            <IconButton onClick={() => handleOpenDocument(annexe)} color="primary">
                              <LaunchIcon />
                            </IconButton>
                        </Box>
                      )}}
                    />
                </Datagrid>
              </ReferenceManyField>
           
          </CardContent>
        
        </Card>

)};

