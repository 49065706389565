import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { useNotify, useRefresh, useCreate, useRedirect } from 'react-admin';
import BillingAccountContractForm from './BillingAccountContractForm';
import { IBillingAccount } from '../../../Services/billingAccount';
import { BillAccountContract } from '../../../Services/billingAccountContract';
import Loader from '../../../Components/Loader';

interface BillingAccountContractModalCreateProps {
	open: boolean;
	amendment?: BillAccountContract;
	billingAccount: IBillingAccount;
	handleClose: () => void;
}

const BillingAccountContractModalCreate: React.FC<
	BillingAccountContractModalCreateProps
> = ({ open, handleClose, amendment, billingAccount }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [create] = useCreate();
	const redirect = useRedirect();

	const [isPenging, setIsPenging] = React.useState(false);

	const handleSave = (data: any) => {
		setIsPenging(true);
		create(
			'billing-account-contracts',
			{
				data: {
					startedAt: data.startedA,
					endedAt: data.endedAt,
					contractOriginRef: data.contractOriginRef,
					automaticRenewal: data.automaticRenewal,
					billingAccountId: billingAccount.id,
				},
			},
			{
				onSuccess: (d) => {
					setIsPenging(false);
					refresh();
					notify('Contract created successfully');
					handleClose();
					redirect(`/billing-account-contracts/${d.id}`);
				},
				onError: (error: any) => {
					setIsPenging(false);
					notify(`Error: ${error.message}`, { type: 'error' });
				},
			}
		);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<Loader show={isPenging} />
			<DialogTitle>
				{amendment ? `Add amendment to ${amendment.ref}` : `New contract`}
			</DialogTitle>
			<BillingAccountContractForm
				onSubmit={handleSave}
				initialValues={{
					contractOriginRef: amendment?.ref,
				}}
				onCancel={handleClose}
			/>
		</Dialog>
	);
};

export default BillingAccountContractModalCreate;
