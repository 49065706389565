import React from 'react';
import {
	BooleanInput,
	Create,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from 'react-admin';
import {
	billingAccountTypeChoices,
	countryChoices,
	currencies,
} from '../../Services/billingAccount';
import {
	useFieldValidation,
	validateMobile,
	validateVatFormat,
} from '../../Utils/validators';
import { Grid, Typography } from '@mui/material';

export const BillingAccountCreate = (props: any) => {
	const { validateField } = useFieldValidation();

	return (
		<Create {...props}>
			<SimpleForm>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography variant="h6" gutterBottom>
							Organisation information
						</Typography>
						<TextInput source="name" validate={[required()]} />
						<TextInput source="organisationName" validate={[required()]} />
						<TextInput source="shareCapital" />
						<SelectInput
							source="shareCapitalCurrency"
							choices={currencies}
							optionText="name"
							optionValue="id"
						/>
						<TextInput source="tradeAndCompaniesRegister" />
						<TextInput source="siren" />
						<TextInput source="duns" />
						<Typography variant="h6" gutterBottom>
							Organisation adress
						</Typography>
						<TextInput source="adress" validate={[required()]} />
						<TextInput source="zipCode" validate={[required()]} />
						<TextInput source="city" validate={[required()]} />
						<SelectInput
							source="country"
							choices={countryChoices}
							optionText="name"
							optionValue="id"
							validate={[required()]}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="h6" gutterBottom>
							Signer information
						</Typography>
						<TextInput
							source="signFirstname"
							label="Firstname"
							validate={[required()]}
						/>
						<TextInput
							source="signLastname"
							label="Lastname"
							validate={[required()]}
						/>
						<TextInput
							source="signQuality"
							label="Quality (pdg, dsi, ...)"
							validate={[required()]}
						/>
						<TextInput
							source="signPhone"
							label="Mobile phone (+33...)"
							validate={[
								required(),
								validateMobile,
								validateField('signPhone'),
							]}
						/>
						<TextInput source="signEmail" label="Email" />

						<Typography variant="h6" gutterBottom>
							Billing information
						</Typography>
						<TextInput
							source="vat"
							validate={[validateVatFormat, validateField('vat')]}
						/>
						<TextInput
							source="pennylaneId"
							label="Pennylane Identifiant client"
							helperText="If empty, a new customer account will be create in pennylane"
						/>
						<SelectInput
							source="billingAccountType"
							choices={billingAccountTypeChoices}
							optionText="name"
							optionValue="id"
							validate={[required(), validateField('billingAccountType')]}
						/>
						<TextInput source="billEmail" />
						<BooleanInput label="Indice Syntec" source="syntecIndice" />
						<BooleanInput label="Always enabled" source="alwaysEnabled" />
					</Grid>
				</Grid>
			</SimpleForm>
		</Create>
	);
};
