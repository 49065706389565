import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import {
	BooleanInput,
	SelectInput,
	SimpleForm,
	TextInput,
	useDataProvider,
	useNotify,
} from 'react-admin';
import {
	billingAccountTypeChoices,
	countryChoices,
	currencies,
} from '../../../Services/billingAccount';
import {
	useFieldValidation,
	validateMobile,
	validateVatFormat,
} from '../../../Utils/validators';

interface BillingAccountInfosProps {
	billingAccount: any;
}

export const BillingAccountInfos: React.FC<BillingAccountInfosProps> = ({
	billingAccount,
}) => {
	const { setFieldErrors, validateField } = useFieldValidation();

	const notify = useNotify();

	const dataProvider = useDataProvider();

	const handleSubmit = async (data: any) => {
		try {
			await dataProvider.update('billing-accounts', {
				id: billingAccount.id,
				data,
				previousData: billingAccount,
			});
			notify('Billing account updated successfully!', { type: 'success' });
		} catch (error: any) {
			if (error.body.fields) {
				const errors = error.body.fields.reduce(
					(
						acc: Record<string, string>,
						fieldError: { field: string; message: string }
					) => {
						acc[fieldError.field] = fieldError.message;
						return acc;
					},
					{}
				);
				setFieldErrors(errors);
			}
			notify(`Error: ${error.message}`, { type: 'error' });
		}
	};

	return (
		<Card>
			<SimpleForm onSubmit={handleSubmit}>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Typography variant="h6" gutterBottom>
								Organisation information
							</Typography>
							<TextInput source="name" />
							<TextInput source="organisationName" />
							<TextInput source="shareCapital" />
							<SelectInput
								source="shareCapitalCurrency"
								choices={currencies}
								optionText="name"
								optionValue="id"
							/>
							<TextInput source="tradeAndCompaniesRegister" />
							<TextInput source="siren" />
							<TextInput source="duns" />
							<Typography variant="h6" gutterBottom>
								Organisation adress
							</Typography>
							<TextInput source="adress" />
							<TextInput source="zipCode" />
							<TextInput source="city" />
							<SelectInput
								source="country"
								choices={countryChoices}
								optionText="name"
								optionValue="id"
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="h6" gutterBottom>
								Signer information
							</Typography>
							<TextInput source="signFirstname" label="Firstname" />
							<TextInput source="signLastname" label="Lastname" />
							<TextInput source="signQuality" label="Quality (pdg, dsi, ...)" />
							<TextInput
								source="signPhone"
								label="Mobile phone (+33...)"
								validate={[validateMobile, validateField('signPhone')]}
							/>
							<TextInput source="signEmail" label="Email" />

							<Typography variant="h6" gutterBottom>
								Billing information
							</Typography>
							<TextInput
								source="vat"
								validate={[validateVatFormat, validateField('vat')]}
							/>
							<TextInput
								source="pennylaneId"
								label="Pennylane Identifiant client"
								helperText="If empty, a new customer account will be create in pennylane"
							/>
							<SelectInput
								source="billingAccountType"
								choices={billingAccountTypeChoices}
								optionText="name"
								optionValue="id"
								validate={[validateField('billingAccountType')]}
							/>
							<TextInput source="billEmail" />
							<BooleanInput label="Indice Syntec" source="syntecIndice" />
							<BooleanInput label="Always enabled" source="alwaysEnabled" />
						</Grid>
					</Grid>
				</CardContent>
			</SimpleForm>
		</Card>
	);
};
