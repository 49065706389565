import { IOfferDetail } from './offerDetails';
import r from '../Utils/fetch';
import { IInvoice } from './invoice';

const w: any = window;
const endPoint = `${w.API_BILL}`;

export interface IOffer {
	id: number;
	name: string;
	offerFor: string;
	startedAt: Date;
	endedAt: Date;
	enabled: boolean;
	offerDetails: IOfferDetail[];
}

export const offerDurationUnits = [
	{ id: 'day', name: 'Day' },
	{ id: 'week', name: 'Week' },
	{ id: 'month', name: 'Month' },
	{ id: 'year', name: 'Year' },
];

export const calculator = (data: IInvoice): Promise<IInvoice> =>
	r({
		url: `${endPoint}/v1/offers/calculator`,
		method: 'POST',
		data,
	});
