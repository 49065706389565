import r from '../Utils/fetch';

const w: any = window;
const endPoint = `${w.API_BILL}`;

export interface IInvoiceRow {
	connectorId: string;
	skuParent: string;
	sku: string;
	nbrInsertedRows: number;
}

export const findAll = (filters: any): Promise<IInvoiceRow> =>
	r({
		url: `${endPoint}/v1/admin/invoice-rows`,
		method: 'GET',
		data: filters,
	});
