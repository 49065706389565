//@ts-nocheck
import React, { useState } from 'react';
import {
	EditBase,
	SimpleForm,
	SelectInput,
	BooleanInput,
	DateInput,
	TextInput,
	MenuItemLink,
	useGetOne,
	Loading,
	useNotify,
	useRefresh,
	useDataProvider,
} from 'react-admin';
import {
	Card,
	CardContent,
	Typography,
	Grid,
	Box,
	MenuItem,
	MenuList,
	ListItemIcon,
	ListItemText,
	Divider,
	Tooltip,
} from '@mui/material';
import {
	BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED,
	billingAccountContractStatus,
	download,
	prepareContractToSign,
	sendToSign,
} from '../../Services/billingAccountContract';
import { generateDocuments } from '../../Services/billingAccountContractDocuments';
import { Routes, Route, useParams, Link } from 'react-router-dom';
import { BillingAccountContractProducts } from './Screens/BillingAccountContractProducts';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import SendIcon from '@mui/icons-material/Send';
import PlusIcon from '@mui/icons-material/Add';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ConstructionIcon from '@mui/icons-material/Construction';
import InboxIcon from '@mui/icons-material/Inbox';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { BillingAccountContractDocuments } from './Screens/BillingAccountContractDocuments';
import BillingAccountContractModalCreate from '../BillingAccounts/BillingAccountContracts/BillingAccountContractModalCreate';
import { useFieldValidation, validateMobile } from '../../Utils/validators';
import { currencies } from '../../Services/billingAccount';
import { nl2br } from '../../Utils/nl2br';
import { BillingAccountContractQuotations } from './Screens/BillingAccountContractQuotations';
import StepperContract from './Components/StepperContract';

export const BillingAccountContractEdit = (props: any) => {
	const { id } = useParams();
	const notify = useNotify();
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const { setFieldErrors, validateField } = useFieldValidation();

	const { data: record } = useGetOne('billing-account-contracts', { id });
	const [contractOpen, setContractOpen] = useState(false);

	const [loading, setLoading] = useState(false);

	const handleContractOpen = () => setContractOpen(true);
	const handleContractClose = () => setContractOpen(false);

	const handleGenerateContractDoc = async () => {
		if (id) {
			setLoading(true);
			try {
				const res = await generateDocuments(id);
				refresh();
				notify('Contract document generated successfully!', {
					type: 'success',
				});
				window.open(
					`https://docs.google.com/drive/folders/${res.gDriveFolderId}`,
					'_blank'
				);
			} catch (e: any) {
				notify(`Error generating contract document! ${nl2br(e.message)}`, {
					type: 'error',
				});
			}

			setLoading(false);
		}
	};

	const handlePrepareContractToSign = async () => {
		if (id) {
			setLoading(true);
			try {
				await prepareContractToSign(id);
				refresh();
				setLoading(false);
				notify('Contract prepared for sign', { type: 'success' });
			} catch (e: any) {
				notify(`Error sending contract to sign! ${e.message}`, {
					type: 'error',
				});
			}

			setLoading(false);
		}
	};

	const handleSendToSign = async () => {
		if (id) {
			setLoading(true);
			try {
				await sendToSign(id);
				refresh();
				setLoading(false);
				notify('Contract sent for signing successfully!', { type: 'success' });
			} catch (e: any) {
				notify(`Error sending contract to sign! ${e.message}`, {
					type: 'error',
				});
			}

			setLoading(false);
		}
	};

	const handleDownloadContract = async () => {
		if (id) {
			setLoading(true);
			try {
				const base64Zip = await download(id); // Assurez-vous que la fonction `download` retourne la chaîne Base64

				const blob = base64ToBlob(base64Zip.content, base64Zip.type);
				const url = URL.createObjectURL(blob);

				// Créez un lien pour déclencher le téléchargement
				const link = document.createElement('a');
				link.href = url;
				link.download = base64Zip.filename; // Nom du fichier à télécharger
				document.body.appendChild(link);
				link.click();

				// Nettoyez les ressources
				document.body.removeChild(link);
				URL.revokeObjectURL(url);

				refresh();
				setLoading(false);
				notify('Contract downloaded successfully!', { type: 'success' });
			} catch (e: any) {
				notify(`Error downloading contract! ${e.message}`, { type: 'error' });
				setLoading(false);
			}
		}
	};

	// Fonction utilitaire pour convertir une chaîne Base64 en Blob
	const base64ToBlob = (base64: string, mimeType: string): Blob => {
		const byteCharacters = atob(base64); // Décodage de la base64
		const byteNumbers = new Array(byteCharacters.length)
			.fill(0)
			.map((_, i) => byteCharacters.charCodeAt(i));
		const byteArray = new Uint8Array(byteNumbers);
		return new Blob([byteArray], { type: mimeType });
	};

	const handleSubmit = async (data: any) => {
		try {
			await dataProvider.update('billing-account-contracts', {
				id: record.id,
				data,
				previousData: record,
			});
			notify('Billing account updated successfully!', { type: 'success' });
		} catch (error: any) {
			const errors = error.body.fields.reduce(
				(
					acc: Record<string, string>,
					fieldError: { field: string; message: string }
				) => {
					acc[fieldError.field] = fieldError.message;
					return acc;
				},
				{}
			);

			setFieldErrors(errors);

			notify(`Error: ${error.message}`, { type: 'error' });
		}
	};

	if (!record) return null;

	return (
		<EditBase {...props}>
			<React.Fragment>
				<StepperContract contract={record} />

				{loading && (
					<Box
						sx={{
							position: 'fixed', // Permet de couvrir toute la fenêtre
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(255, 255, 255, 0.8)', // Couleur de fond semi-transparente
							display: 'flex', // Centrer le contenu
							alignItems: 'center',
							justifyContent: 'center',
							zIndex: 2500, // Assurez-vous qu'il est au-dessus des autres éléments
						}}
					>
						<Loading />
					</Box>
				)}
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<Routes>
							<Route
								path="/"
								element={<BillingAccountContractProducts contract={record} />}
							/>
							<Route
								path="/documents"
								element={<BillingAccountContractDocuments record={record} />}
							/>
							<Route
								path="/quotations"
								element={<BillingAccountContractQuotations record={record} />}
							/>
						</Routes>
					</Grid>
					<Grid item xs={4}>
						{record && (
							<BillingAccountContractModalCreate
								open={contractOpen}
								amendment={record}
								billingAccount={record.billingAccount}
								handleClose={handleContractClose}
							/>
						)}
						<SimpleForm sx={{ padding: 0 }} onSubmit={handleSubmit}>
							<Card style={{ marginBottom: 20, width: '100%' }}>
								<CardContent>
									<Typography variant="h6" gutterBottom>
										Contract Status
									</Typography>

									<TextInput source="ref" disabled />
									<SelectInput
										source="status"
										choices={billingAccountContractStatus}
										optionText="name"
										optionValue="id"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>

									<Box display="flex" flexDirection="row" width="100%">
										<Box flex={1} mr="0.5em">
											<DateInput
												source="startedAt"
												disabled={
													record?.status ===
													BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
												}
											/>
										</Box>
										<Box flex={1} mr="0.5em">
											<DateInput
												source="endedAt"
												disabled={
													record?.status ===
													BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
												}
											/>
										</Box>
									</Box>

									<Typography
										variant="h6"
										gutterBottom
										style={{ textAlign: 'center' }}
									>
										{new Intl.NumberFormat('fr-FR', {
											style: 'currency',
											currency: 'EUR',
										}).format(record?.amount!)}
									</Typography>
								</CardContent>
							</Card>

							<Card style={{ marginBottom: 20, width: '100%' }}>
								<MenuList>
									<MenuItemLink to={`/billing-account-contracts/${record.id}`}>
										<ListItemIcon>
											<ShoppingBasketIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Products</ListItemText>
									</MenuItemLink>
									<MenuItemLink
										to={`/billing-account-contracts/${record.id}/documents`}
									>
										<ListItemIcon>
											<InboxIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Contract Documents</ListItemText>
									</MenuItemLink>
									<MenuItemLink
										to={`/billing-account-contracts/${record.id}/quotations`}
									>
										<ListItemIcon>
											<ReceiptIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Quotations</ListItemText>
									</MenuItemLink>
									<MenuItemLink
										to={`/billing-accounts/${record?.billingAccount?.id}`}
									>
										<ListItemIcon>
											<PermContactCalendarIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Billing Account</ListItemText>
									</MenuItemLink>
									{record?.gDriveFolderSignedId && (
										<MenuItem onClick={handleDownloadContract}>
											<ListItemIcon>
												<CloudDownloadIcon fontSize="small" />
											</ListItemIcon>
											<ListItemText>Download Contracts</ListItemText>
										</MenuItem>
									)}
									<Divider />
									{!record?.gDriveFolderSignedId &&
										record?.offerDetails?.length > 0 && (
											<MenuItem onClick={handleGenerateContractDoc}>
												<ListItemIcon>
													<ReceiptLong fontSize="small" />
												</ListItemIcon>
												<ListItemText>
													<Tooltip
														title={'generate gdrive contract source'}
														placement="top"
													>
														Generate Contract Docs
													</Tooltip>
												</ListItemText>
											</MenuItem>
										)}
									{record?.documents.length > 0 &&
										!record?.gDriveFolderSignedId && (
											<MenuItem onClick={handlePrepareContractToSign}>
												<ListItemIcon>
													<ConstructionIcon fontSize="small" />
												</ListItemIcon>
												<ListItemText>
													<Tooltip
														title={
															'Send document from gdrive source folder to yousign and add signers'
														}
														placement="top"
													></Tooltip>
													Prepare contract for sign
												</ListItemText>
											</MenuItem>
										)}
									{record?.yousignSignId && !record?.gDriveFolderSignedId && (
										<MenuItem onClick={handleSendToSign}>
											<ListItemIcon>
												<SendIcon fontSize="small" />
											</ListItemIcon>
											<ListItemText>Send contract for sign</ListItemText>
										</MenuItem>
									)}
									<MenuItem onClick={handleContractOpen}>
										<ListItemIcon>
											<PlusIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText>Create amendment</ListItemText>
									</MenuItem>
								</MenuList>
							</Card>
							<Card>
								<CardContent>
									<Typography variant="h6" gutterBottom>
										Contract Details
									</Typography>

									<Box mb={2} display={'flex'}>
										<Typography variant="body1" component="div">
											{record?.billingAccount?.name || 'Nom indisponible'}
										</Typography>
										<Link
											to={`/billing-accounts/${record?.billingAccount?.id}`}
										>
											<EditIcon fontSize="small" sx={{ marginRight: 0.5 }} />
										</Link>
									</Box>

									<TextInput
										source="organisationName"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="adress"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="zipCode"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="city"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="country"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="shareCapital"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<SelectInput
										source="shareCapitalCurrency"
										choices={currencies}
										optionText="name"
										optionValue="id"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="tradeAndCompaniesRegister"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="siren"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="duns"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="signFirstname"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="signLastname"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="signQuality"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="signEmail"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>
									<TextInput
										source="signPhone"
										validate={[validateMobile, validateField('signPhone')]}
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
									/>

									<BooleanInput
										label="Automatic Renewal"
										disabled={
											record?.status === BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED
										}
										source="automaticRenewal"
									/>

									<Box></Box>
								</CardContent>
							</Card>
						</SimpleForm>
					</Grid>
				</Grid>
			</React.Fragment>
		</EditBase>
	);
};
