import { IBillingAccount } from './billingAccount';
import { IInvoiceLibelle } from './invoiceLibelle';
import { IInvoiceRow } from './invoiceRows';
import r from '../Utils/fetch';

const w: any = window;
const endPoint = `${w.API_BILL}`;

export const INVOICE_STATUS_PROJECT = 'project';
export const INVOICE_STATUS_CLOSE = 'close';
export const INVOICE_STATUS_BILLED = 'billed';

export const InvoiceStatus = [
	{ id: INVOICE_STATUS_PROJECT, name: 'Project' },
	{
		id: INVOICE_STATUS_CLOSE,
		name: 'Close',
	},
	{
		id: INVOICE_STATUS_BILLED,
		name: 'Billed',
	},
];

export interface IInvoice {
	id?: number;
	billingAccountId?: string | null;
	billingAccount?: IBillingAccount | null;
	status?: string;
	contractId?: any | null;
	periodStartedAt?: Date;
	periodEndedAt?: Date;
	total?: number;
	billingAccountType: string;
	rows: IInvoiceRow[];
	libelles: IInvoiceLibelle[];
}

export const sync = (filters: any): Promise<IInvoiceRow> =>
	r({
		url: `${endPoint}/v1/admin/invoices/process`,
		method: 'POST',
		data: filters,
	});
