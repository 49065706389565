import React, { useState } from 'react';
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import {
	SimpleForm,
	Toolbar,
	SaveButton,
	TextInput,
	ReferenceInput,
	SelectInput,
	required,
} from 'react-admin';
import EuroIcon from '@mui/icons-material/Euro';
import DeleteIcon from '@mui/icons-material/Delete';
import { IOfferDetail } from '../../../Services/offerDetails';

interface OfferDetailFormProps {
	onSubmit: (data: any) => void;
	initialValues?: IOfferDetail;
	onCancel: () => void;
}

const OfferDetailForm: React.FC<OfferDetailFormProps> = ({
	onSubmit,
	initialValues,
	onCancel,
}) => {
	const [lines, setLines] = useState(initialValues?.lines || []);
	const [newLine, setNewLine] = useState({ from: 0, to: 0, amount: 0 });
	const [unitLine, setUnitLine] = useState(10000);

	const handleAddLine = () => {
		// Ajouter la ligne après validation
		setLines([
			...lines,
			{
				...newLine,
			},
		]);
		setNewLine({ from: 0, to: 0, amount: 0 });
	};

	const handleLineChange = (field: string, value: string) => {
		const parsedValue = parseFloat(value);
		setNewLine({ ...newLine, [field]: isNaN(parsedValue) ? '' : parsedValue });
	};

	const handleDeleteLine = (index: number) => {
		// Supprimer la ligne à l'index spécifié
		const updatedLines = lines.filter((_, i) => i !== index);
		setLines(updatedLines);
	};

	const handleSubmit = (data: any) => {
		onSubmit({ ...data, lines }); // Ajout de `lines` dans l'objet de soumission
	};

	return (
		<SimpleForm
			onSubmit={handleSubmit}
			defaultValues={initialValues}
			toolbar={
				<Toolbar style={{ justifyContent: 'space-between' }}>
					<SaveButton label="Save" alwaysEnable />
					<Button onClick={onCancel} color="secondary">
						Cancel
					</Button>
				</Toolbar>
			}
		>
			<ReferenceInput label="Sku" source="sku" reference="skus">
				<SelectInput optionText="name" validate={required()} />
			</ReferenceInput>

			<Box display="flex">
				<Box flex={1} mr="0.5em">
					<TextInput
						source="nbr"
						type="number"
						defaultValue={1}
						helperText="of connector(s) id"
						parse={(value) => parseInt(value, 10)}
					/>
				</Box>
				<Box flex={1} mr="0.5em">
					<TextInput
						source="amount"
						label="Amount"
						helperText="per years"
						parse={(value) => parseFloat(value)}
						type="number"
						InputProps={{
							endAdornment: <EuroIcon style={{ marginLeft: 8 }} />,
						}}
					/>
				</Box>
				<Box flex={1} mr="0.5em">
					<TextInput
						source="linesIncluded"
						label="Nbr of lines offered"
						format={(value) => (value ? value.toLocaleString('fr-FR') : '')}
						parse={(value) => parseInt(value.replace(/\s/g, ''), 10)}
					/>
				</Box>
				<Box flex={1} mr="0.5em">
					<TextInput
						source="unitLine"
						label="Unity of nbr rows packs"
						format={(value) => (value ? value.toLocaleString('fr-FR') : '')}
						parse={(value) => {
							const parsedValue = parseInt(value.replace(/\s/g, ''), 10);
							setUnitLine(parsedValue || 10000);
							return parsedValue;
						}}
					/>
				</Box>
			</Box>

			<Typography variant="h6" gutterBottom>
				Contract Detail Lines (per months)
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>From</TableCell>
						<TableCell>To</TableCell>
						<TableCell>Amount</TableCell>
						<TableCell>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{lines.map((line, index) => (
						<TableRow key={index}>
							<TableCell>
								{line.from} (
								{(line.from * unitLine + 1).toLocaleString('fr-FR')})
							</TableCell>
							<TableCell>
								{line.to} ({(line.to * unitLine).toLocaleString('fr-FR')})
							</TableCell>
							<TableCell>{line.amount}</TableCell>
							<TableCell>
								<Button
									onClick={() => handleDeleteLine(index)}
									color="secondary"
								>
									<DeleteIcon />
								</Button>
							</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell>
							<TextField
								label="From (>)"
								value={newLine.from}
								onChange={(e) => handleLineChange('from', e.target.value)}
							/>
						</TableCell>
						<TableCell>
							<TextField
								label="To (<=)"
								value={newLine.to}
								onChange={(e) => handleLineChange('to', e.target.value)}
							/>
						</TableCell>
						<TableCell>
							<TextField
								label="Amount"
								value={newLine.amount}
								onChange={(e) => handleLineChange('amount', e.target.value)}
								InputProps={{
									endAdornment: <EuroIcon style={{ marginLeft: 8 }} />,
								}}
							/>
						</TableCell>
						<TableCell>
							<Button onClick={handleAddLine} color="primary">
								+
							</Button>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</SimpleForm>
	);
};

export default OfferDetailForm;
