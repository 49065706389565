import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import SyncForm from '../Form/SyncForm';
import { sync } from '../../../Services/invoice';
import { useNotify } from 'react-admin';

interface SyncModalProps {
	open: boolean;
	handleClose: () => void;
}

const SyncModal: React.FC<SyncModalProps> = ({ open, handleClose }) => {
	const notify = useNotify();

	const handleSave = async (data: any) => {
		try {
			await sync(data);
			handleClose();
			notify('Sync in progress', { type: 'info' });
		} catch (err: any) {
			notify(err.message, { type: 'error' });
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Sync form</DialogTitle>

			<SyncForm onSubmit={handleSave} onCancel={handleClose} />
		</Dialog>
	);
};

export default SyncModal;
