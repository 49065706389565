import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import {
	useNotify,
	useRefresh,
	useRecordContext,
	useCreate,
} from 'react-admin';
import { IOffer } from '../../../Services/offer';
import OfferDetailForm from './OfferDetailForm';

interface OfferDetailsModalCreateProps {
	open: boolean;
	handleClose: () => void;
	defaultValues?: any;
	offer: IOffer;
}

const OfferDetailsModalCreate: React.FC<OfferDetailsModalCreateProps> = ({
	open,
	handleClose,
	defaultValues,
	offer,
}) => {
	const record = useRecordContext<IOffer>(); // Contexte pour obtenir le user actuel
	const notify = useNotify();
	const refresh = useRefresh();
	const [create] = useCreate();

	const handleSave = (data: any) => {
		// Ajoutez l'ID du User aux données du formulaire
		create(
			'offer-details',
			{ data: { ...data, offerId: record?.id } },
			{
				onSuccess: () => {
					refresh();
					notify('Line created successfully');
					handleClose();
				},
				onError: (error: any) => {
					notify(`Error: ${error.message}`, { type: 'error' });
				},
			}
		);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Detail on offer</DialogTitle>

			<OfferDetailForm
				onSubmit={handleSave}
				onCancel={handleClose}
				initialValues={defaultValues}
				offer={offer}
			/>
		</Dialog>
	);
};

export default OfferDetailsModalCreate;
