import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import {
	Datagrid,
	FunctionField,
	ReferenceManyField,
	TextField,
} from 'react-admin';
import ConfirmDeleteButton from '../../../Components/Button/ConfirmDeleteBtn';

interface BillingAccountPaymentTypeProps {
	billingAccount: any;
}

export const BillingAccountPaymentTypes: React.FC<
	BillingAccountPaymentTypeProps
> = ({ billingAccount }) => {
	return (
		<Card style={{ marginBottom: 20 }}>
			<CardContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: 20,
					}}
				>
					<Typography variant="h6" gutterBottom>
						Payment cards
					</Typography>
				</div>

				<ReferenceManyField
					label="Payments cards"
					reference="billing-account-payment-types"
					target="billingAccountId"
				>
					<Datagrid bulkActionButtons={false}>
						<TextField source="cardBrand" label="Type" />
						<TextField source="last4" label="Last Num" />
						<FunctionField
							label="Expiration"
							render={(rec) =>
								rec.expireMonth && rec.expireYear
									? `${rec.expireMonth}/${rec.expireYear}`
									: 'N/A'
							}
						/>
						<FunctionField
							source="isDefault"
							label="Default"
							render={(rec) => (rec.isDefault ? 'Yes' : 'No')}
						/>
						<FunctionField
							render={(rec) => (
								<ConfirmDeleteButton
									record={rec}
									title="Delete"
									entity="payment card"
									description="Are you sure to delete this payment type ?"
									withRedirect={false}
									confirmMessage="Payment type is deleted"
								/>
							)}
						/>
					</Datagrid>
				</ReferenceManyField>
			</CardContent>
		</Card>
	);
};
