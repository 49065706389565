import React from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	Alert,
} from '@mui/material';
import {
	SimpleForm,
	Toolbar,
	SaveButton,
	TextInput,
	useNotify,
} from 'react-admin';
import * as quotationService from '../../../Services/billingAccountContractQuotation';

interface BillingAccountContractDocumentQuotationModalEditProps {
	open: boolean;
	handleClose: () => void;
	contract: any;
}

const BillingAccountContractDocumentQuotationModalEdit: React.FC<
	BillingAccountContractDocumentQuotationModalEditProps
> = ({ open, handleClose, contract }) => {
	const notify = useNotify();
	const [err, setErr] = React.useState<string | null>(null);

	const handleSave = async (data: any) => {
		setErr(null);
		try {
			await quotationService.create({
				billingAccountContractId: contract.id,
				num: data.num,
			});
			notify('Quotation added', { type: 'info' });

			handleClose();
		} catch (e: any) {
			setErr(e.message);
			notify(e.message, { type: 'error' });
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Add Quotation to contract</DialogTitle>
			<DialogContent>
				<p>
					Cette étape permet d'assigner un devis pennylane au contrat si le
					devis est déjà existant, ou de générer le devis dans pennylane à
					partir des infos du contrat . <br />
					Le devis sera utilisé pour générer une facture par l'équipe admin de
					quanti.
				</p>
				{err && <Alert severity="error">{err}</Alert>}
			</DialogContent>
			{/* Formulaire pour ajouter un projet utilisateur */}
			<SimpleForm
				onSubmit={handleSave}
				toolbar={
					<Toolbar style={{ justifyContent: 'space-between' }}>
						<SaveButton label="Save" alwaysEnable />
						<Button onClick={handleClose} color="secondary">
							Cancel
						</Button>
					</Toolbar>
				}
			>
				<TextInput
					source="num"
					label="Num (si existant)"
					helperText="Si vous ne renseignez pas de numéro de devis, un devis sera créé dans pennylane."
				/>
			</SimpleForm>
		</Dialog>
	);
};

export default BillingAccountContractDocumentQuotationModalEdit;
