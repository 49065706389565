import r from '../Utils/fetch';

export interface IBillingAccount {
	id: number;
}

const w: any = window;
const endPoint = `${w.API_BILL}`;

export const BILLING_ACCOUNT_TYPE_FREETRIAL = 'freetrial',
	BILLING_ACCOUNT_TYPE_PAYASYOUGO = 'payasyougo',
	BILLING_ACCOUNT_TYPE_ENTREPRISE = 'entreprise',
	BILLING_ACCOUNT_STATUS_OK = 100,
	BILLING_ACCOUNT_STATUS_PAIEMENT_CARD_WARNING = 110,
	BILLING_ACCOUNT_STATUS_BILL_NOT_PAYED_WARNING = 120,
	BILLING_ACCOUNT_STATUS_NO_PAIEMENT_CARD = 200,
	BILLING_ACCOUNT_STATUS_PAIEMENT_CARD_FAILED = 210,
	BILLING_ACCOUNT_STATUS_PAIEMENT_CARD_EXPIRED = 220,
	BILLING_ACCOUNT_STATUS_CONTRACT_NOT_SIGNED = 300,
	BILLING_ACCOUNT_STATUS_BILL_NOT_PAYED = 310;

export const billingAccountTypeChoices = [
	{ id: BILLING_ACCOUNT_TYPE_FREETRIAL, name: 'Free Trial' },
	{ id: BILLING_ACCOUNT_TYPE_PAYASYOUGO, name: 'Pay as you go' },
	{ id: BILLING_ACCOUNT_TYPE_ENTREPRISE, name: 'Entreprise' },
];

export const billingAccountStatus = [
	{ id: BILLING_ACCOUNT_STATUS_OK, name: 'Ok' },
	{
		id: BILLING_ACCOUNT_STATUS_PAIEMENT_CARD_WARNING,
		name: 'Payement card warning',
	},
	{
		id: BILLING_ACCOUNT_STATUS_BILL_NOT_PAYED_WARNING,
		name: 'Bill with card not payed',
	},
	{
		id: BILLING_ACCOUNT_STATUS_NO_PAIEMENT_CARD,
		name: 'No payment card saved',
	},
	{
		id: BILLING_ACCOUNT_STATUS_PAIEMENT_CARD_FAILED,
		name: 'Payment card failed',
	},
	{
		id: BILLING_ACCOUNT_STATUS_PAIEMENT_CARD_EXPIRED,
		name: 'Payement card expired',
	},
	{
		id: BILLING_ACCOUNT_STATUS_CONTRACT_NOT_SIGNED,
		name: 'Contract not signed',
	},
	{ id: BILLING_ACCOUNT_STATUS_BILL_NOT_PAYED, name: 'Bill not payed' },
];

export const currencies = [
	{ id: 'EURO', name: '€' },
	{ id: 'DOLLAR', name: '$' },
];

export const countryChoices = [
	{ id: 'US', name: 'United States' },
	{ id: 'CA', name: 'Canada' },
	{ id: 'GB', name: 'United Kingdom' },
	{ id: 'FR', name: 'France' },
	{ id: 'DE', name: 'Germany' },
	{ id: 'IT', name: 'Italy' },
	{ id: 'ES', name: 'Spain' },
	{ id: 'JP', name: 'Japan' },
	{ id: 'CN', name: 'China' },
	{ id: 'IN', name: 'India' },
	{ id: 'BR', name: 'Brazil' },
	{ id: 'AU', name: 'Australia' },
	{ id: 'MX', name: 'Mexico' },
	{ id: 'RU', name: 'Russia' },
	{ id: 'ZA', name: 'South Africa' },
	{ id: 'KR', name: 'South Korea' },
	{ id: 'AR', name: 'Argentina' },
	{ id: 'CH', name: 'Switzerland' },
	{ id: 'SE', name: 'Sweden' },
	{ id: 'NL', name: 'Netherlands' },
];

export const refresh = (id: string): Promise<IBillingAccount> =>
	r({
		url: `${endPoint}/v1/admin/billing-accounts/${id}/refresh`,
		method: 'GET',
	});

export const freetrialDates = (
	id: string,
	data: any
): Promise<IBillingAccount> =>
	r({
		url: `${endPoint}/v1/admin/billing-accounts/${id}/dates`,
		method: 'PATCH',
		data,
	});
