import React from 'react';
import { Menu, MenuItemLink, MenuProps } from 'react-admin';
import { Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import StoreIcon from '@mui/icons-material/Store';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const CustomMenu: React.FC<MenuProps> = (props) => (
	<Menu {...props}>
		{/* Titre non cliquable */}
		<Typography
			variant="subtitle1"
			style={{ margin: '16px', borderBottom: 'solid 1 px #000' }}
		>
			Ressource Management
		</Typography>

		{/* Éléments du menu */}
		<MenuItemLink
			to="/projects"
			primaryText="Projects"
			leftIcon={<InboxIcon />}
		/>
		<MenuItemLink
			to="/billing-accounts"
			primaryText="Billing Accounts"
			leftIcon={<StoreIcon />}
		/>
		<MenuItemLink
			to="/billing-account-contracts"
			primaryText="Contracts"
			leftIcon={<HistoryEduIcon />}
		/>
		<MenuItemLink
			to="/invoices"
			primaryText="Invoices"
			leftIcon={<ReceiptIcon />}
		/>
		<MenuItemLink
			to="/invoice-payments"
			primaryText="Payments"
			leftIcon={<CreditCardIcon />}
		/>
		<MenuItemLink to="/users" primaryText="Users" leftIcon={<PeopleIcon />} />

		<Typography variant="subtitle1" style={{ margin: '16px' }}>
			Parameters
		</Typography>
		<MenuItemLink
			to="/offers"
			primaryText="Offers"
			leftIcon={<LocalOfferIcon />}
		/>
	</Menu>
);

export default CustomMenu;
