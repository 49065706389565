import React from 'react';
import { Dialog, DialogTitle, Button } from '@mui/material';
import { SimpleForm, Toolbar, SaveButton, TextInput, useNotify } from 'react-admin';
import { setFolders } from '../../../Services/billingAccountContract';


interface BillingAccountContractDocumentAnnexeModalEditProps {
  open: boolean;
  handleClose: () => void;
  contractId: number;
}

const BillingAccountContractDocumentFoldersModalEdit: React.FC<BillingAccountContractDocumentAnnexeModalEditProps> = ({ open, handleClose, contractId }) => {
 
  
  const notify = useNotify();

  const handleSave = async (data: any) => {

    try {
      await setFolders(contractId, {
        gDriveFolderId: data.gDriveFolderId, 
        gDriveFolderSrcId: data.gDriveFolderSrcId, 
        gDriveFolderSignedId: data.gDriveFolderSignedId, 
    });
      notify('Document added', { type: 'info' });

      handleClose();
    }catch(e: any) {
      notify(e.message, { type: 'error' });
    }

  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Set manualy folders GDrive to contract</DialogTitle>
      
        {/* Formulaire pour ajouter un projet utilisateur */}
        <SimpleForm
          onSubmit={handleSave} // Utilisation de handleSave pour soumettre le formulaire
          toolbar={
            <Toolbar style={{justifyContent: "space-between"}}>
              <SaveButton label="Save" />
              <Button onClick={handleClose} color="secondary">Cancel</Button>
            </Toolbar>
          }
        >
          <TextInput source="gDriveFolderId" label="Contract Folder" />
          <TextInput source="gDriveFolderSrcId" label="Src Contract Folder" />
          <TextInput source="gDriveFolderSignedId" label="Sign Contract Folder"  />

        </SimpleForm>
      
    </Dialog>
  );
};

export default BillingAccountContractDocumentFoldersModalEdit;
