import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';


interface BillingAccountSkusProps {
  billingAccount: any;
}

export const BillingAccountSkus:React.FC<BillingAccountSkusProps> = ({billingAccount}) => {
  

  return (
    <Card style={{ marginBottom: 20 }}>
    <CardContent>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Typography variant="h6" gutterBottom>
          Sku's
        </Typography>
      </div>

      <ReferenceManyField
        label="Offer Details"
        reference="offer-details"
        target="billingAccountId"
        
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="skuParent" label="Sku Parent" />
          <TextField source="sku" label="Sku" />
          <TextField source="nbr" label="nbr" />
          <TextField source="nbrType" label="nbrType" />
          <TextField source="billingAccountContract.ref" label="Contract" />
          <TextField source="billingAccountContract.status" label="Status" />
        </Datagrid>
      </ReferenceManyField>
    </CardContent>
  </Card>
)};

