import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import UsageDashboard from '../../../Components/UsageDashboard/UsageDashboard';


interface BillingAccountUsageProps {
  billingAccount: any;
}

export const BillingAccountUsage:React.FC<BillingAccountUsageProps> = ({billingAccount}) => {
  
  return (

    <Card>
    <CardContent>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Typography variant="h6" gutterBottom>
          Usage
        </Typography>
      </div>

      <UsageDashboard filter={{billingAccountId: billingAccount.id}} groupBy='day' range={["2024-01-01", "2024-12-31"]}  />

      
    </CardContent>
  </Card>
)};

