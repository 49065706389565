//@ts-nocheck
import React, { useState } from 'react';
import {
	EditBase,
	TopToolbar,
	useRecordContext,
	useGetOne,
	useNotify,
	MenuItemLink,
	SimpleForm,
	DateInput,
	Toolbar,
	SaveButton,
	useDataProvider,
} from 'react-admin';
import {
	Card,
	CardContent,
	Typography,
	Grid,
	MenuList,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Box,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import TimelineIcon from '@mui/icons-material/Timeline';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PeopleIcon from '@mui/icons-material/People';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StoreIcon from '@mui/icons-material/Store';
import {
	BILLING_ACCOUNT_STATUS_OK,
	BILLING_ACCOUNT_TYPE_ENTREPRISE,
	BILLING_ACCOUNT_TYPE_FREETRIAL,
	BILLING_ACCOUNT_TYPE_PAYASYOUGO,
	billingAccountStatus,
	freetrialDates,
	refresh,
} from '../../Services/billingAccount';
import { green, red } from '@mui/material/colors';
import { Route, Routes, useParams } from 'react-router';
import { BillingAccountContract } from './BillingAccountContracts/BillingAccountContract';
import { BillingAccountPaymentTypes } from './BillingAccountPaymentTypes/BillingAccountPaymentTypes';
import { BillingAccountSkus } from './BillingAccountSkus/BillingAccountSkus';
import { BillingAccountUsers } from './BillingAccountUsers/BillingAccountUsers';
import { BillingAccountProjects } from './BillingAccountProjects/BillingAccountProjects';
import { BillingAccountUsage } from './BillingAccountUsage/BillingAccountUsage';
import { BillingAccountInvoices } from './BillingAccountInvoices/BillingAccountInvoices';
import { BillingAccountInfos } from './BillingAccountInfos/BillingAccountInfos';
import { useFieldValidation } from '../../Utils/validators';

const Breadcrumb = () => {
	const record = useRecordContext();
	return (
		<TopToolbar>
			<Typography variant="subtitle1">
				Home / Billing Account / {record ? record.name : 'Edit'}
			</Typography>
		</TopToolbar>
	);
};

const SaveToolbar = () => (
	<Toolbar>
		<SaveButton />
	</Toolbar>
);

export const BillingAccountEdit = (props: any) => {
	const { id } = useParams();

	const {
		data: record,
		isLoading,
		refetch,
	} = useGetOne('billing-accounts', { id });

	const [refreshIsPending, setRefreshIsPending] = useState(false);
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const { setFieldErrors, validateField } = useFieldValidation();

	const getStatusName = (status: number) => {
		const statusObj = billingAccountStatus.find((s) => s.id === status);
		return statusObj ? statusObj.name : 'Unknown Status';
	};

	const handleSubmit = async (data: any) => {
		try {
			await freetrialDates(record.id, data);
			notify('Billing account updated successfully!', { type: 'success' });
		} catch (error: any) {
			if (error.body.fields) {
				const errors = error.body.fields.reduce(
					(
						acc: Record<string, string>,
						fieldError: { field: string; message: string }
					) => {
						acc[fieldError.field] = fieldError.message;
						return acc;
					},
					{}
				);
				setFieldErrors(errors);
			}
			notify(`Error: ${error.message}`, { type: 'error' });
		}
	};

	const handleRefresh = async () => {
		setRefreshIsPending(true);
		try {
			await refresh(record.id);
			notify('Billing account refreshed successfully!', { type: 'success' });
			refetch();
		} catch (error: any) {
			notify(`Error: ${error.message}`, { type: 'error' });
		}
		setRefreshIsPending(false);
	};

	const handleOpenFolder = () => {
		window.open(
			`https://drive.google.com/drive/folders/${record.folderDriveId}`,
			'_blank'
		);
	};

	if (isLoading) return null;
	if (!record) return null;

	return (
		<EditBase {...props}>
			<React.Fragment>
				<Breadcrumb />
				{/* Utilisation d'une grille à deux colonnes */}
				<Grid container spacing={2}>
					{/* Colonne principale avec les projets associés */}
					<Grid item xs={8}>
						<Routes>
							<Route
								path="/"
								element={<BillingAccountUsage billingAccount={record} />}
							/>
							<Route
								path="/contracts"
								element={<BillingAccountContract billingAccount={record} />}
							/>
							<Route
								path="/payement-types"
								element={<BillingAccountPaymentTypes billingAccount={record} />}
							/>
							<Route
								path="/skus"
								element={<BillingAccountSkus billingAccount={record} />}
							/>
							<Route
								path="/users"
								element={<BillingAccountUsers billingAccount={record} />}
							/>
							<Route
								path="/projects"
								element={<BillingAccountProjects billingAccount={record} />}
							/>
							<Route
								path="/infos"
								element={<BillingAccountInfos billingAccount={record} />}
							/>
							<Route
								path="/invoices"
								element={<BillingAccountInvoices billingAccount={record} />}
							/>
						</Routes>
					</Grid>

					{/* Colonne secondaire avec le formulaire utilisateur */}
					<Grid item xs={4}>
						<Card style={{ marginBottom: 20, width: '100%' }}>
							<CardContent>
								<Typography variant="h6" gutterBottom>
									Billing Account Status ({record.billingAccountType})
								</Typography>
								<div>
									<Typography
										style={{
											display: 'inline-block',
											color: record.enabled ? green[500] : red[500], // Couleur conditionnelle
											fontWeight: 'bold',
										}}
									>
										{record.enabled
											? 'Account is enabled'
											: 'Account is disabled'}
									</Typography>
								</div>
								<div>
									<Typography
										style={{
											display: 'inline-block',
											color:
												record.status === BILLING_ACCOUNT_STATUS_OK
													? green[500]
													: red[500], // Couleur conditionnelle
											fontWeight: 'bold',
										}}
									>
										{getStatusName(record.status)}
									</Typography>
								</div>
							</CardContent>
							<MenuList>
								<MenuItem onClick={handleRefresh} disabled={refreshIsPending}>
									<ListItemIcon>
										<RefreshIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText>Refresh</ListItemText>
								</MenuItem>
							</MenuList>
						</Card>
						{record.billingAccountType == BILLING_ACCOUNT_TYPE_FREETRIAL && (
							<Card style={{ marginBottom: 20, width: '100%' }}>
								<SimpleForm
									sx={{ padding: 0 }}
									toolbar={<SaveToolbar />}
									onSubmit={handleSubmit}
								>
									<CardContent>
										<Typography variant="h6" gutterBottom>
											Free trial dates
										</Typography>
										<Box display="flex" flexDirection="row" width="100%">
											<Box flex={1} mr="0.5em">
												<DateInput source="startedAt" />
											</Box>
											<Box flex={1} mr="0.5em">
												<DateInput source="endedAt" />
											</Box>
										</Box>
									</CardContent>
								</SimpleForm>
							</Card>
						)}
						<Card style={{ marginBottom: 20, width: '100%' }}>
							<MenuList>
								<MenuItemLink to={`/billing-accounts/${record.id}`}>
									<ListItemIcon>
										<TimelineIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText>Account Usage</ListItemText>
								</MenuItemLink>

								<MenuItemLink to={`/billing-accounts/${record.id}/infos`}>
									<ListItemIcon>
										<StoreIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText>Account Informations</ListItemText>
								</MenuItemLink>

								{record &&
									record.billingAccountType ===
										BILLING_ACCOUNT_TYPE_ENTREPRISE && (
										<MenuItemLink
											to={`/billing-accounts/${record.id}/contracts`}
										>
											<ListItemIcon>
												<HistoryEduIcon fontSize="small" />
											</ListItemIcon>
											<ListItemText>Contracts</ListItemText>
										</MenuItemLink>
									)}

								{record &&
									record.billingAccountType ===
										BILLING_ACCOUNT_TYPE_ENTREPRISE && (
										<MenuItemLink to={`/billing-accounts/${record.id}/skus`}>
											<ListItemIcon>
												<LocalOfferIcon fontSize="small" />
											</ListItemIcon>
											<ListItemText>Avaible Sku's</ListItemText>
										</MenuItemLink>
									)}

								<MenuItemLink to={`/billing-accounts/${record.id}/users`}>
									<ListItemIcon>
										<PeopleIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText>Users</ListItemText>
								</MenuItemLink>

								{record &&
									record.billingAccountType ===
										BILLING_ACCOUNT_TYPE_PAYASYOUGO && (
										<MenuItemLink
											to={`/billing-accounts/${record.id}/payement-types`}
										>
											<ListItemIcon>
												<CreditCardIcon fontSize="small" />
											</ListItemIcon>
											<ListItemText>Payements types</ListItemText>
										</MenuItemLink>
									)}
								<MenuItemLink to={`/billing-accounts/${record.id}/invoices`}>
									<ListItemIcon>
										<ReceiptIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText>Invoices</ListItemText>
								</MenuItemLink>
								<MenuItem onClick={handleOpenFolder}>
									<ListItemIcon>
										<OpenInNewIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText>Open customer folder</ListItemText>
								</MenuItem>
							</MenuList>
						</Card>
					</Grid>
				</Grid>
			</React.Fragment>
		</EditBase>
	);
};
