import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	Filter,
	TextInput,
	FunctionField,
} from 'react-admin';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('fr-FR'); // Format français : JJ/MM/AAAA
};

const BillingAccountAccountFilter = (props: any) => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
);
export const BillingAccountAccountList = (props: any) => (
	<List {...props} filters={<BillingAccountAccountFilter />}>
		<Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
			<TextField source="billingAccount.name" />
			<TextField source="ref" label="Ref" />
			<TextField source="contractOrigin.ref" label="Ref Origin" />
			<FunctionField
				source="startedAt"
				label="Started At"
				render={(rec) => formatDate(rec.startedAt)}
			/>
			<FunctionField
				source="endedAt"
				label="Ended At"
				render={(rec) => formatDate(rec.endedAt)}
			/>

			<FunctionField
				source="amount"
				label="Amount"
				render={(rec) =>
					new Intl.NumberFormat('fr-FR', {
						style: 'currency',
						currency: 'EUR',
					}).format(rec.amount!)
				}
			/>
			<TextField source="status" />
			<FunctionField
				render={(record) => (
					<ConfirmDeleteButton
						record={record}
						title="Delete contract"
						entity="billing-account-contracts"
						description="Are you sure to delete this contract ?"
						confirmMessage="Contract is deleted"
					/>
				)}
			/>
		</Datagrid>
	</List>
);
