import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { IOfferDetail } from '../../../Services/offerDetails';
import OfferDetailForm from './OfferDetailForm';

interface OfferDetailsModalEditProps {
	open: boolean;
	handleClose: () => void;
	record?: IOfferDetail | null;
}

const OfferDetailsModalEdit: React.FC<OfferDetailsModalEditProps> = ({
	open,
	handleClose,
	record,
}) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const [update] = useUpdate();

	const handleSave = (data: IOfferDetail) => {
		if (!record) return;

		update(
			'offer-details',
			{ id: record.id, data },
			{
				mutationMode: 'pessimistic',
				onSuccess: () => {
					refresh();
					notify('Line updated successfully');
					handleClose();
				},
				onError: (error: any) => {
					notify(`Error: ${error.message}`, { type: 'error' });
				},
			}
		);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Edit Contract Detail</DialogTitle>
			{record && (
				<OfferDetailForm
					onSubmit={handleSave}
					initialValues={record}
					onCancel={handleClose}
				/>
			)}
		</Dialog>
	);
};

export default OfferDetailsModalEdit;
