import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	Filter,
	TextInput,
	EditButton,
	FunctionField,
} from 'react-admin';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';
import { billingAccountStatus } from '../../Services/billingAccount';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import { green, red } from '@mui/material/colors';
import { Typography } from '@mui/material';

const BillingAccountFilter = (props: any) => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
);

export const BillingAccountList = (props: any) => {
	const getStatusName = (status: number) => {
		const statusObject = billingAccountStatus.find(
			(item) => item.id === status
		);
		return statusObject ? statusObject.name : 'Unknown status';
	};

	return (
		<List {...props} filters={<BillingAccountFilter />} perPage={50}>
			<Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
				<TextField source="name" />
				<TextField source="organisationName" />
				<TextField source="city" />
				<TextField source="phone" />
				<TextField source="billingAccountType" />
				<FunctionField
					label="Enabled"
					render={(record) =>
						record.enabled ? (
							<CheckIcon style={{ color: green[500] }} titleAccess="Enabled" />
						) : (
							<WarningIcon style={{ color: red[500] }} titleAccess="Disabled" />
						)
					}
				/>
				<FunctionField
					label="Status"
					render={(record) => (
						<Typography
							style={{
								display: 'inline-block',
								color: record.status === 100 ? green[500] : red[500], // Couleur conditionnelle
								fontWeight: 'bold', // Optionnel : pour mettre en avant le texte
							}}
						>
							{getStatusName(record.status)}
						</Typography>
					)}
				/>
				<EditButton label="Edit" />
				<FunctionField
					render={(record) => (
						<ConfirmDeleteButton
							record={record}
							title="Delete billing account"
							entity="billing-accounts"
							description="Are you sure to delete this billing account?"
							confirmMessage="Billing account is deleted"
						/>
					)}
				/>
			</Datagrid>
		</List>
	);
};
