import React from 'react';
import { Box, Button } from '@mui/material';
import {
	SimpleForm,
	ReferenceInput,
	SelectInput,
	Toolbar,
	SaveButton,
	required,
	FormDataConsumer,
	TextInput,
	DateInput,
} from 'react-admin';

interface SyncFormProps {
	onCancel: () => void;
	onSubmit: (data: any) => void;
}

const SyncForm: React.FC<SyncFormProps> = ({ onCancel, onSubmit }) => {
	return (
		<SimpleForm
			toolbar={
				<Toolbar style={{ justifyContent: 'space-between' }}>
					<SaveButton label="Sync" alwaysEnable />
					<Button onClick={onCancel} color="secondary">
						Cancel
					</Button>
				</Toolbar>
			}
			onSubmit={onSubmit}
		>
			<ReferenceInput
				label="Sku Parent"
				source="skuParent"
				reference="skus"
				filter={{ parentSkuId: '' }}
			>
				<SelectInput optionText="name" validate={required()} />
			</ReferenceInput>

			<FormDataConsumer>
				{({ formData }) =>
					formData.skuParent &&
					formData.skuParent !== 'allconnectors' && (
						<ReferenceInput
							label="Sku"
							source="sku"
							reference="skus"
							filter={{ parentSkuId: formData.skuParent }}
						>
							<SelectInput optionText="name" />
						</ReferenceInput>
					)
				}
			</FormDataConsumer>

			<TextInput source="billingAccountId" />
			<Box display="flex" flexDirection="row" width="100%">
				<Box flex={1} mr="1em">
					<DateInput source="startDate" />
				</Box>
				<Box flex={1} ml="1em">
					<DateInput source="endDate" />
				</Box>
			</Box>
			<TextInput source="requestId" />
			<TextInput source="connectorId" />
			<TextInput source="serviceName" />
		</SimpleForm>
	);
};

export default SyncForm;
