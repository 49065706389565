export const INVOICE_PAYEMENT_STATUS_TRY = 100,
	INVOICE_PAYEMENT_STATUS_FAILED = 150,
	INVOICE_PAYEMENT_STATUS_SUCCESS = 200;

export const billingAccountTypeChoices = [
	{ id: INVOICE_PAYEMENT_STATUS_TRY, name: 'Wait try payment' },
	{ id: INVOICE_PAYEMENT_STATUS_FAILED, name: 'Failed' },
	{ id: INVOICE_PAYEMENT_STATUS_SUCCESS, name: 'Success' },
];

export const invoicePaymentStatus = [
	{ id: INVOICE_PAYEMENT_STATUS_TRY, name: 'Wait try payment' },
	{
		id: INVOICE_PAYEMENT_STATUS_FAILED,
		name: 'Failed',
	},
	{
		id: INVOICE_PAYEMENT_STATUS_SUCCESS,
		name: 'Success',
	},
];
