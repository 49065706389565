import { Button, Card, CardContent, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
	Datagrid,
	FunctionField,
	ReferenceManyField,
	TextField,
} from 'react-admin';
import ConfirmDeleteButton from '../../../Components/Button/ConfirmDeleteBtn';
import BillingAccountContractModalCreate from './BillingAccountContractModalCreate';

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('fr-FR'); // Format français : JJ/MM/AAAA
};

interface BillingAccountContractProps {
	billingAccount: any;
}

export const BillingAccountContract: React.FC<BillingAccountContractProps> = ({
	billingAccount,
}) => {
	const [contractOpen, setContractOpen] = useState(false);
	const handleContractOpen = () => {
		if (billingAccount.pennylaneId === '') {
			alert('Please set the PennyLane ID before creating a contract');
		} else {
			setContractOpen(true);
		}
	};
	const handleContractClose = () => setContractOpen(false);

	return (
		<Card style={{ marginBottom: 20 }}>
			<BillingAccountContractModalCreate
				open={contractOpen}
				billingAccount={billingAccount}
				handleClose={handleContractClose}
			/>
			<CardContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: 20,
					}}
				>
					<Typography variant="h6" gutterBottom>
						Contracts
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={handleContractOpen}
					>
						+
					</Button>
				</div>

				<ReferenceManyField
					label="Contracts"
					reference="billing-account-contracts"
					target="billingAccountId"
					sort={{
						field: '"BillingAccountContract"."startedAt"',
						order: 'DESC',
					}}
				>
					<Datagrid bulkActionButtons={false}>
						<TextField source="ref" label="Ref" />
						<TextField source="contractOrigin.ref" label="Ref Origin" />
						<FunctionField
							source="startedAt"
							label="Started At"
							render={(rec) => formatDate(rec.startedAt)}
						/>
						<FunctionField
							source="endedAt"
							label="Ended At"
							render={(rec) => formatDate(rec.endedAt)}
						/>
						<FunctionField
							source="amount"
							label="Amount"
							render={(rec) =>
								new Intl.NumberFormat('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								}).format(rec.amount!)
							}
						/>

						<TextField source="status" label="Status" />

						<FunctionField
							render={(rec) => (
								<div>
									<ConfirmDeleteButton
										record={rec}
										title="Delete Contract"
										entity="billing-account-contracts"
										description="Are you sure to delete this contract ?"
										withRedirect={false}
										confirmMessage="Contract is deleted"
									/>
								</div>
							)}
						/>
					</Datagrid>
				</ReferenceManyField>
			</CardContent>
		</Card>
	);
};
