import { Box, Grid } from '@mui/material';
import React from 'react';
import {
	BooleanInput,
	Create,
	DateInput,
	SelectInput,
	SimpleForm,
	TextInput,
} from 'react-admin';
import { billingAccountTypeChoices } from '../../Services/billingAccount';
import moment from 'moment';

export const OfferCreate = (props: any) => {
	return (
		<Create {...props}>
			<SimpleForm>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<SelectInput
							source="offerFor"
							label="for"
							choices={billingAccountTypeChoices}
						/>
						<TextInput source="name" />
						<TextInput source="internalName" />
						<Box display="flex" flexDirection="row" width="100%">
							<Box mr={1} flex={1}>
								<DateInput
									source="startedAt"
									parse={(value) =>
										value ? moment(value).format('YYYY-MM-DDTHH:mm:ssZ') : null
									}
								/>
							</Box>
							<Box ml={1} flex={1}>
								<DateInput
									source="endedAt"
									parse={(value) =>
										value ? moment(value).format('YYYY-MM-DDTHH:mm:ssZ') : null
									}
								/>
							</Box>
						</Box>
						<BooleanInput label="Enabled" source="enabled" />
					</Grid>
				</Grid>
			</SimpleForm>
		</Create>
	);
};
