import React from 'react';
import { Box } from '@mui/material';
import {
	SimpleForm,
	SaveButton,
	TextInput,
	SelectInput,
	ReferenceInput,
	required,
} from 'react-admin';

interface SimulatorFormProps {
	onSubmit: (data: any) => void;
	onCancel: () => void;
	error?: string | null;
}

const SimulatorForm: React.FC<SimulatorFormProps> = ({ onSubmit, error }) => {
	return (
		<SimpleForm
			onSubmit={onSubmit}
			toolbar={
				<Box display="flex" justifyContent="center">
					<SaveButton label="Add" alwaysEnable />
				</Box>
			}
		>
			<ReferenceInput label="Sku Parent" source="sku" reference="skus">
				<SelectInput optionText="name" validate={required()} />
			</ReferenceInput>

			<Box display="flex" width={'100%'} justifyContent="space-between">
				<Box flex={1} mr={1}>
					<TextInput label="Connector ID" source="connectorId" />
				</Box>
				<Box flex={1} ml={1}>
					<TextInput
						label="Nbr of active rows"
						source="nbrInsertedRows"
						format={(value) => (value ? value.toLocaleString('fr-FR') : '')}
						parse={(value) => parseInt(value.replace(/\s/g, ''), 10)}
					/>
				</Box>
			</Box>

			{error && <Box color="error.main">{error}</Box>}
		</SimpleForm>
	);
};

export default SimulatorForm;
