import React, { useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	Filter,
	TextInput,
	EditButton,
	FunctionField,
	TopToolbar,
} from 'react-admin';
import { Button, Chip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SyncIcon from '@mui/icons-material/Sync';
import {
	INVOICE_STATUS_BILLED,
	INVOICE_STATUS_CLOSE,
	INVOICE_STATUS_PROJECT,
	InvoiceStatus,
} from '../../Services/invoice';
import SyncModal from './Modals/SyncModal';

const InvoiceFilter = (props: any) => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
	</Filter>
);

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('fr-FR'); // Format français : JJ/MM/AAAA
};

const ListActions = () => {
	const [open, setOpen] = useState<boolean>(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<TopToolbar>
				<Button
					variant="text"
					color="primary"
					startIcon={<SyncIcon />}
					onClick={handleOpen}
				>
					Sync
				</Button>
			</TopToolbar>
			<SyncModal open={open} handleClose={handleClose} />
		</>
	);
};

export const InvoiceList = (props: any) => {
	const getStatusName = (status: string) => {
		const statusObject = InvoiceStatus.find((item) => item.id === status);
		return statusObject ? statusObject.name : 'Unknown status';
	};

	return (
		<>
			<List
				{...props}
				filters={<InvoiceFilter />}
				perPage={100}
				exporter={false}
				actions={<ListActions />}
			>
				<Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
					<TextField source="billingAccount.name" />
					<TextField source="billingAccountType" />
					<FunctionField
						source="periodStartedAt"
						render={(record) => formatDate(record.periodStartedAt)}
					/>
					<FunctionField
						source="periodEndedAt"
						render={(record) => formatDate(record.periodEndedAt)}
					/>
					<FunctionField
						label="Status"
						render={(record) => {
							switch (record.status) {
								case INVOICE_STATUS_PROJECT:
									return (
										<Chip
											icon={<PendingActionsIcon />}
											label={getStatusName(record.status)}
											color="primary"
											size="small"
										/>
									);
								case INVOICE_STATUS_CLOSE:
									return (
										<Chip
											icon={<WarningIcon />}
											label={getStatusName(record.status)}
											color="warning"
											size="small"
										/>
									);
								case INVOICE_STATUS_BILLED:
									return (
										<Chip
											icon={<CheckIcon />}
											label={getStatusName(record.status)}
											color="success"
											size="small"
										/>
									);
								default:
									return (
										<Chip
											icon={<ErrorIcon />}
											label={'unkown status'}
											color="error"
											size="small"
										/>
									);
							}
						}}
					/>
					<FunctionField
						source="amountTotal"
						label="Amount"
						render={(rec) =>
							new Intl.NumberFormat('fr-FR', {
								style: 'currency',
								currency: 'EUR',
							}).format(rec.amountTotal!)
						}
					/>
					<FunctionField label="" render={(record) => <EditButton />} />
				</Datagrid>
			</List>
		</>
	);
};
