import React, { useState } from 'react';
import {
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SimulatorForm from './SimulatorForm';
import { calculator } from '../../Services/offer';
import { IInvoiceRow } from '../../Services/invoiceRows';
import { Toolbar } from 'react-admin';
import { BILLING_ACCOUNT_TYPE_PAYASYOUGO } from '../../Services/billingAccount';
import { IInvoice } from '../../Services/invoice';
import { IInvoiceLibelle } from '../../Services/invoiceLibelle';

interface SimulatorModalProps {
	open: boolean;
	handleClose: () => void;
}

export const SimulatorModal: React.FC<SimulatorModalProps> = ({
	open,
	handleClose,
}: {
	open: boolean;
	handleClose: () => void;
}) => {
	const [error, setError] = useState(null);
	const [invoice, setInvoice] = useState<IInvoice | null>();
	const [invoiceRows, setInvoiceRows] = useState<IInvoiceRow[]>([]);
	const [formKey, setFormKey] = useState(Date.now());
	const [isPending, setIsPending] = useState(false);

	const onSubmit = async (data: any) => {
		const i = JSON.parse(JSON.stringify(invoiceRows));
		i.push(data);
		setInvoiceRows(i);
		setFormKey(Date.now());
	};

	const handleDelete = (index: number) => {
		const l = JSON.parse(JSON.stringify(invoiceRows));
		l.splice(index, 1);
		setInvoiceRows(l);
	};

	const handleCalculate = async () => {
		setIsPending(true);
		try {
			const res = await calculator({
				billingAccountType: BILLING_ACCOUNT_TYPE_PAYASYOUGO,
				rows: invoiceRows,
				libelles: [],
			});
			setInvoice(res);
		} catch (e: any) {
			setError(e.message);
		} finally {
			setIsPending(false);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Simulator</DialogTitle>

			<SimulatorForm
				key={formKey}
				onSubmit={onSubmit}
				error={error}
				onCancel={handleClose}
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Connector Id</TableCell>
						<TableCell>Sku</TableCell>
						<TableCell align="center">Active rows</TableCell>
						<TableCell align="center"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{invoiceRows.map((invoiceRow: IInvoiceRow, index: number) => (
						<TableRow key={index}>
							<TableCell>{invoiceRow.connectorId}</TableCell>
							<TableCell>{invoiceRow.sku}</TableCell>
							<TableCell align="center">
								{invoiceRow.nbrInsertedRows.toLocaleString('fr-FR')}
							</TableCell>
							<TableCell align="center">
								<IconButton color="error" onClick={() => handleDelete(index)}>
									<DeleteIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{invoice && invoice.libelles && invoice.libelles.length > 0 && (
				<>
					<DialogTitle>Results</DialogTitle>

					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Libelle</TableCell>
								<TableCell align="center">Connector</TableCell>
								<TableCell align="center">Billed Rows</TableCell>
								<TableCell align="center">Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{invoice.libelles.map(
								(libelle: IInvoiceLibelle, index: number) => (
									<TableRow key={index}>
										<TableCell>
											<div>
												<strong>
													{libelle.skuParent} {libelle.sku} {libelle.type}
												</strong>
											</div>

											<div>
												Offer :{' '}
												{libelle.offerDetail && libelle.offerDetail.offer
													? libelle.offerDetail.offer.name
													: ''}
											</div>
										</TableCell>
										<TableCell align="center">{libelle.qty}</TableCell>
										<TableCell align="center">
											{libelle.unitPrice.toLocaleString('fr-FR', {
												style: 'currency',
												currency: 'EUR',
											})}
										</TableCell>
									</TableRow>
								)
							)}
						</TableBody>
					</Table>
				</>
			)}
			<DialogActions>
				<Toolbar style={{ width: '100%', justifyContent: 'center' }}>
					<Button
						fullWidth
						variant="contained"
						onClick={handleCalculate}
						disabled={isPending}
					>
						{isPending ? `Loading` : `Simulate`}
					</Button>
				</Toolbar>
			</DialogActions>
		</Dialog>
	);
};
