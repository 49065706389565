import React, { useEffect, useState } from 'react';
import {
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { IInvoiceLibelle } from '../../../Services/invoiceLibelle';
import { Datagrid, FunctionField, TextField } from 'react-admin';
import { findAll, IInvoiceRow } from '../../../Services/invoiceRows';

interface InvoiceRowsModalProps {
	open: boolean;
	handleClose: () => void;
	invoiceLibelle: IInvoiceLibelle | null;
}

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('fr-FR'); // Format français : JJ/MM/AAAA
};

export const InvoiceRowsModal: React.FC<InvoiceRowsModalProps> = ({
	open,
	handleClose,
	invoiceLibelle,
}) => {
	// États pour stocker les données et éviter l'exécution initiale
	const [data, setData] = useState<IInvoiceRow[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	// Effet pour récupérer les données uniquement quand la modal s'ouvre
	useEffect(() => {
		if (open && invoiceLibelle) {
			setIsLoading(true);
			setError(null);

			// Exécuter useGetList uniquement ici
			const fetchData = async () => {
				try {
					const response = await findAll({
						filter: {
							invoiceId: invoiceLibelle.invoiceId,
							sku: invoiceLibelle.sku,
						},
					});
					//@ts-ignore
					setData(response);
				} catch (err) {
					setError('Erreur lors du chargement des données');

					setData([]);
				} finally {
					setIsLoading(false);
				}
			};

			fetchData();
		} else {
			// Reset des données quand la modal se ferme
			setData([]);
		}
	}, [open, invoiceLibelle]); // Déclenché seulement si `open` ou `invoiceLibelle` change

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle>Rows</DialogTitle>
			<DialogContent>
				{isLoading ? (
					<CircularProgress />
				) : error ? (
					<p>Erreur lors du chargement des données.</p>
				) : data?.length === 0 ? (
					<p>Aucune ligne trouvée.</p>
				) : (
					<Datagrid bulkActionButtons={false} data={data}>
						<FunctionField
							source="timestamp"
							render={(record) => formatDate(record.timestamp)}
						/>
						<TextField source="date" label="Date" />
						<TextField source="connectorId" label="Connector Id" />
						<TextField source="sku" label="Sku" />
						<TextField source="requestId" label="requestId" />
						<TextField source="nbrInsertedRows" label="Inserted Rows" />
						<TextField source="nbrOfDeletedRows" label="Deleted Rows" />
						<TextField source="nbrOfActiveRows" label="Active Rows" />
					</Datagrid>
				)}
			</DialogContent>
		</Dialog>
	);
};
